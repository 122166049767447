import React from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { NavLink } from "react-router-dom";
import Seo from "../seo/Seo";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";
const DisplayAds = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings8 = {
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,
    speed: 500,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Slider section start-----------------------------------------
  function SampleNextArrow2(props) {
    const { onClick } = props;
    return (
      <div className="slider-custome-button-next2" onClick={onClick}>
        <ArrowForwardIosIcon className="forword-arrow2" />
      </div>
    );
  }

  function SamplePrevArrow2(props) {
    const { onClick } = props;
    return (
      <div className="slider-custome-button-prev2" onClick={onClick}>
        <ArrowBackIosIcon className="backword-arrow2" />
      </div>
    );
  }

  const schema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Grank",
    url: "https://www.grank.co.in/",
    potentialAction: {
      "@type": "SearchAction",
      target:
        "https://www.grank.co.in/display-advertising-agency{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };
  return (
    <>
      <Seo
        title="Dispaly Advertising Agency In India - G-Rank"
        description="Partner with G-Rank, a renowned display ads agency in India, to create compelling ad campaigns that capture attention and boost your business growth."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="Best display advertising service in India,
        display advertising service company in bangalore,
        display advertising service company in hyderabad"
        searchengines="ALL"
        canonical="https://www.grank.co.in/display-advertising-agency"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/creative-content/creative-content-banner1.png"
            alt="G-Rank"
            title="creative content"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/creative-content/creative-content-banner1-mobile.png"
            alt="G-Rank"
            title="creative content"
          />
          <div className="creative-banner-div">
            <motion.h1
              className="animate-charcter"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            >
              Display Advertising <br /> Agency
            </motion.h1>
            <h2>
              Amplify Your <br />
              Online Presence!
            </h2>

            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Get Started!</NavLink>
            </motion.h3>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{ float: "right", paddingTop: "40px", color: "#fff" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/creative-content-agency"
              >
                <p>Creative Content</p>
              </NavLink>

              <Typography color="#fff">Display Advertising Agency</Typography>
            </Breadcrumbs>
            {/* <div>o</div> */}
          </div>
        </div>
        {/* banner end */}

        {/* text section start */}
        <section className="back-grey">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="creative-header"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Drive Results{" "}
                  <span className="d-block">
                    with Display Advertising Process
                  </span>
                </motion.h4>
                <motion.h2
                  className="creative-header2"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                >
                  In today's digital world, where people spend a significant
                  portion of their time browsing the internet, display
                  advertising is seen as powerful tool for businesses to connect
                  with their target audience. It allows businesses to create
                  visually appealing and engaging ads that can be targeted to
                  specific demographics, interests, and behaviors which will not
                  only increase brand awareness but also generate leads, and
                  drive sales. It is a cost-effective and measurable way to
                  reach your target audience and achieve your business goals.
                </motion.h2>
              </div>
            </div>
          </div>
        </section>
        {/* text section start */}
        {/* slider up section start */}
        <section className="back-yellow">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="creative-header"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Our <span> Process</span>
                </motion.h4>
              </div>
              <div className="col-12">
                <Slider {...settings8}>
                  <div className="slider-div77">
                    <motion.div
                      className="box"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <div className="hid-box2">
                        <img
                          className=""
                          src="assest/photos/creative-content/display-ads/creative-ads1.png"
                          alt="G-Rank"
                          title="creative content"
                        />
                        <h4>
                          Campaign Goal
                          <br /> and KPIs
                        </h4>
                      </div>
                      <p className="hid-box">
                        Define campaign objectives and key performance
                        indicators (KPIs).
                      </p>
                    </motion.div>
                  </div>
                  <div className="slider-div77">
                    <motion.div
                      className="box"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <div className="hid-box2">
                        <img
                          className=""
                          src="assest/photos/creative-content/display-ads/creative-ads2.png"
                          alt="G-Rank"
                          title="creative content"
                        />
                        <h4>
                          Audience <br />
                          Segmentation
                        </h4>
                      </div>
                      <p className="hid-box">
                        Identify target audience segments based on demographics
                        and behaviors.
                      </p>
                    </motion.div>
                  </div>
                  <div className="slider-div77">
                    <motion.div
                      className="box"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <div className="hid-box2">
                        <img
                          className=""
                          src="assest/photos/creative-content/display-ads/creative-ads3.png"
                          alt="G-Rank"
                          title="creative content"
                        />
                        <h4>
                          Creative
                          <br /> Development
                        </h4>
                      </div>
                      <p className="hid-box">
                        Design captivating ad creatives aligned with the brand.
                      </p>
                    </motion.div>
                  </div>
                  <div className="slider-div77">
                    <motion.div
                      className="box"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <div className="hid-box2">
                        <img
                          className=""
                          src="assest/photos/creative-content/display-ads/creative-ads4.png"
                          alt="G-Rank"
                          title="creative content"
                        />
                        <h4>
                          Ad Format <br />
                          Selection
                        </h4>
                      </div>
                      <p className="hid-box">
                        Choose suitable ad formats for the campaign's goals
                      </p>
                    </motion.div>
                  </div>
                  <div className="slider-div77">
                    <motion.div
                      className="box"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <div className="hid-box2">
                        <img
                          className=""
                          src="assest/photos/creative-content/display-ads/creative-ads5.png"
                          alt="G-Rank"
                          title="creative content"
                        />
                        <h4>
                          Landing Page <br />
                          Preparation
                        </h4>
                      </div>
                      <p className="hid-box">
                        Create dedicated landing pages with clear CTAs.
                      </p>
                    </motion.div>
                  </div>
                  <div className="slider-div77">
                    <motion.div
                      className="box"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <div className="hid-box2">
                        <img
                          className=""
                          src="assest/photos/creative-content/display-ads/creative-ads6.png"
                          alt="G-Rank"
                          title="creative content"
                        />
                        <h4>
                          Targeting and
                          <br /> Placement
                        </h4>
                      </div>
                      <p className="hid-box">
                        Use precise targeting options and select relevant ad
                        placements.
                      </p>
                    </motion.div>
                  </div>
                  <div className="slider-div77">
                    <motion.div
                      className="box"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <div className="hid-box2">
                        <img
                          className=""
                          src="assest/photos/creative-content/display-ads/creative-ads7.png"
                          alt="G-Rank"
                          title="creative content"
                        />
                        <h4>
                          Tracking and <br /> Analysis
                        </h4>
                      </div>
                      <p className="hid-box">
                        Implement tracking mechanisms and analyze campaign
                        metrics.
                      </p>
                    </motion.div>
                  </div>
                  <div className="slider-div77">
                    <motion.div
                      className="box"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <div className="hid-box2">
                        <img
                          className=""
                          src="assest/photos/creative-content/display-ads/creative-ads8.png"
                          alt="G-Rank"
                          title="creative content"
                        />
                        <h4>Optimization</h4>
                      </div>
                      <p className="hid-box">
                        Continuously refine strategies based on performance
                        insights.
                      </p>
                    </motion.div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        {/* slider up section end */}
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch with Our Display Ads Agency Team Today
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}
        {/* what we do start */}
        <div className="container-fluid branding-sem-consulting-what creative-what-we">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-7 " id="order2">
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                className=" branding-sem-consulting-what-heading"
              >
                <h4>Why to Choose Our Display Advertising Companies</h4>
              </motion.div>
              <div className="branding-sem-consulting-what-text">
                <p>
                  <span>Expertise & Experience : </span>Our experienced team
                  stays abreast of the latest trends and technologies in display
                  advertising. We leverage this knowledge to create and manage
                  effective campaigns that deliver exceptional results.
                </p>
                <p>
                  <span>Customised Solutions : </span>We understand that
                  one-size-fits-all approaches don't work. We tailor campaign
                  strategies to your specific business goals, target audience,
                  and budget.
                </p>
                <p>
                  <span>Full-Service Support : </span>From ad creation and
                  placement to monitoring and optimisation, we handle
                  everything. You'll receive a comprehensive service experience
                  focused on achieving your desired outcomes.
                </p>
                <p>
                  <span>Transparent Reporting : </span>We believe in
                  transparency. You'll receive detailed reports that provide
                  insights into the performance of your display ad campaigns,
                  allowing you to track progress and measure effectiveness.
                </p>
                <p>
                  <span>Targeted Audience Reach : </span>We utilise advanced
                  targeting techniques to ensure your ads reach individuals with
                  a high propensity to be interested in your offerings.
                </p>
                <p>
                  <span>Creative & Appealing Ad Design : </span>Our team designs
                  visually captivating and impactful ad creatives that resonate
                  with your brand identity and capture the audience's attention.
                </p>
                <p>
                  <span>Cost-Effectiveness : </span>We prioritise your budget
                  and strive to deliver the best possible results while
                  maximising your advertising spend.
                </p>
                <p>
                  <span>Real-Time Monitoring & Optimization : </span>We closely
                  monitor the performance of your campaigns and make data-driven
                  adjustments to optimise results for ongoing success.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5 " id="order1">
              <motion.img
                initial={{ opacity: 0, scale: 0, y: 200 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="img-fluid branding-consulting-process-img"
                src="assest/photos/branding/what-we-do-img-blue.png"
                alt="G-Rank"
                title="grank"
              />
            </div>
          </div>
        </div>
        {/* what we do end */}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="creative-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links <span>For You</span>
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        <img
          className="img-fluid w-100"
          src="assest/photos/branding/branding-footer-img.png"
          alt="G-Rank"
          title="creative content"
        />
      </section>
    </>
  );
};

export default DisplayAds;
