import React, { useState, useEffect } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
//import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
//import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Seo from "../seo/Seo";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { NavLink, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import CustomerContext from "../blog-folder/CustomerContext";
import AddToCartRediff from "../../../features/frontend/addtocartrediff/AddToCartRediff";
import { loadProducts } from "../../../features/frontend/addtocartrediff/thunk-productrediff";

const RediffMail = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProducts());
  }, [dispatch]);
  // useState section start--------------------------------------

  //  useState section end----------------------------------------
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings4 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // -------------------
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",
      "domain book": "/domain-booking",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "branding consulting firm": "/branding-consulting-firm",
      "branding strategy consultant": "/brand-strategy-consultant",
      "brand positioning agency": "/brand-positioning-agency",
      "brand identity agency": "/brand-identity-agency",
      "branding communication agency": "/branding-communication-agency",
      "performance marketing company": "/performance-marketing-company",
      "social media marketing agency": "/social-media-marketing-agency",
      "search engine marketing agency": "/search-engine-marketing-agency",
      "organic marketing agency": "/organic-marketing-agency",
      "search engine optimization services":
        "/search-engine-optimization-services",
      "search engine optimization": "/search-engine-optimization-services",
      sem: "/search-engine-optimization-services",
      "social media optimization agency": "/social-media-optimization-agency",
      "creative content agency": "/creative-content-agency",
      "display advertising agency": "/display-advertising-agency",
      "website development company": "/website-development-company",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
      "seo services": "/search-engine-optimization-services",
      seo: "/search-engine-optimization-services",
      "web development": "/web-development-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "smm service": "/paid-marketing-agency",
      smm: "/paid-marketing-agency",
      "social media marketing": "/paid-marketing-agency",
      "social media optimization": "/organic-social-media-agency",
      "smo service": "/organic-social-media-agency",
      smo: "/organic-social-media-agency",
      "content marketing": "/content-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "brand marketing": "/branding-agency",
      "facebook optimization": "/social-media-agency",
      "instagram optimization": "/social-media-agency",
      "google adwords": "/performance-marketing-company",
      "adwords optimization": "/performance-marketing-company",
      "ads optimization": "/performance-marketing-company",
      "wordpress development": "/wordpress-development-agency",
      "website development": "/web-development-agency",
      website: "/web-development-agency",
      "ecommerce website": "/web-development-agency",
      "paid marketing": "/creative-content-agency",
      "paid advertising": "/creative-content-agency",
      "display ads": "/display-advertising-agency",
      "search ads": "/search-engine-optimization-services",
      "youtube ads": "/branding-consulting-firm",
      "youtube marketing": "/brand-positioning-agency",
      "print design": "/print-design-agency",
      "website design": "/website-development-company",
      "web design": "/website-development-company",
      "web optimization": "/website-development-company",
      "customized website": "/website-development-company",
      "rank keywords": "/search-engine-optimization-services",
      "seo ramking": "/search-engine-optimization-services",
      contact: "/contact",
    };

    // Get the corresponding page based on the input value
    const inputValue = inputData.toLowerCase().trim();

    // Find a matching page based on input
    const matchingPage = Object.keys(pages).find((key) =>
      key.includes(inputValue)
    );

    if (matchingPage) {
      // Redirect to the matched page
      navigate(pages[matchingPage]);
    } else {
      // Navigate to the home page if no match found
      navigate(pages["home"]);
    }
  };
  return (
    <>
      <Seo
        title="Rediffmail Service Provider in Bangalore | Hyderabad"
        description="Rediffmail service provider in bangalore and hyderbad.  Discover how Rediffmail can simplify your email experience and keep you connected with ease."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="rediffmail service provider in bangalore,
        rediffmail service provider in hyderbad,
        best rediffmail service provider in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/rediff-mail"
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/email/email-banner3.png"
            alt="G-Rank"
            title="grank"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/email/email-banner3-mobile.png"
            alt="G-Rank"
            title="grank"
          />

          <div className="email-banner-div1">
            <motion.h1
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Rediff Mail <br />
              Service Provider
            </motion.h1>
            <motion.h2
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Efficiency Meets Communication  
            </motion.h2>

            <motion.h3
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Find Out More</NavLink>
            </motion.h3>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/email-hosting-provider"
              >
                <p>Email</p>
              </NavLink>

              <Typography color="#fff">Rediff Mail</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* second section start */}
        <div>
          <div className="whychoose-sec">
            <div className="container-fluid">
              <h4 className="email-sub-head">Benefits of Rediffmail Pro </h4>
              <Slider {...settings4}>
                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">Enhanced Storage</h2>
                    <p>
                      Rediffmail Pro offers a larger storage capacity, allowing
                      users to store more emails and attachments without
                      worrying about running out of space.
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">Custom Domain</h2>
                    <p>
                      Users can use their custom domain with Rediffmail Pro,
                      giving them a professional and personalized email address.
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">Ad-Free Experience</h2>
                    <p>
                      Rediffmail Pro provides an ad-free email experience,
                      reducing distractions and creating a more focused
                      environment.
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">Advanced Security</h2>
                    <p>
                      The service offers advanced security features such as spam
                      filtering, virus scanning, and encryption, helping to
                      protect users from malicious emails and threats.
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">
                      Priority Customer
                      <br /> Support
                    </h2>
                    <p>
                      Subscribers to Rediffmail Pro often receive priority
                      customer support, ensuring quicker assistance and issue
                      resolution.
                    </p>
                  </div>
                </div>
                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">Synchronization</h2>
                    <p>
                      The service may provide seamless synchronization across
                      devices, ensuring that emails and changes are consistent
                      and up-to-date across multiple platforms.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {/* second section end */}
        {/* logo section start */}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 className="email-sub-head">
                  Rediffmail Pro Services & Plans
                </h4>
              </div>
              <div className="col-12">
                <div className="row">
                  <AddToCartRediff />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* logo section end */}
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links For You
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/*Input start */}
        <section>
          <div className="performance-footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/security/white-and-blue-back.png"
              alt="G-Rank"
              title="grank"
            />
            <div className="performance-footer-div-text">
              <h4>Learn more about our sevices</h4>
              <form className="searchArea" onSubmit={handleSubmit}>
                <button type="submit" tabindex="2">
                  Explore
                </button>
                <div className="inputDiv">
                  <input
                    type="text"
                    id="inputData"
                    name="inputData"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    requiredtabindex="1"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
        {/*Input start */}
      </section>
    </>
  );
};

export default RediffMail;
