import React, { useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
//import AddIcon from "@mui/icons-material/Add";
//import RemoveIcon from "@mui/icons-material/Remove";
import Seo from "../seo/Seo";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";

const ExtentedValidationSSL = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();
  // useState section start--------------------------------------

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  //  useState section end----------------------------------------
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // -------------------
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",
      "domain book": "/domain-booking",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "branding consulting firm": "/branding-consulting-firm",
      "branding strategy consultant": "/brand-strategy-consultant",
      "brand positioning agency": "/brand-positioning-agency",
      "brand identity agency": "/brand-identity-agency",
      "branding communication agency": "/branding-communication-agency",
      "performance marketing company": "/performance-marketing-company",
      "social media marketing agency": "/social-media-marketing-agency",
      "search engine marketing agency": "/search-engine-marketing-agency",
      "organic marketing agency": "/organic-marketing-agency",
      "search engine optimization services":
        "/search-engine-optimization-services",
      "search engine optimization": "/search-engine-optimization-services",
      sem: "/search-engine-optimization-services",
      "social media optimization agency": "/social-media-optimization-agency",
      "creative content agency": "/creative-content-agency",
      "display advertising agency": "/display-advertising-agency",
      "website development company": "/website-development-company",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
      "seo services": "/search-engine-optimization-services",
      seo: "/search-engine-optimization-services",
      "web development": "/web-development-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "smm service": "/paid-marketing-agency",
      smm: "/paid-marketing-agency",
      "social media marketing": "/paid-marketing-agency",
      "social media optimization": "/organic-social-media-agency",
      "smo service": "/organic-social-media-agency",
      smo: "/organic-social-media-agency",
      "content marketing": "/content-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "brand marketing": "/branding-agency",
      "facebook optimization": "/social-media-agency",
      "instagram optimization": "/social-media-agency",
      "google adwords": "/performance-marketing-company",
      "adwords optimization": "/performance-marketing-company",
      "ads optimization": "/performance-marketing-company",
      "wordpress development": "/wordpress-development-agency",
      "website development": "/web-development-agency",
      website: "/web-development-agency",
      "ecommerce website": "/web-development-agency",
      "paid marketing": "/creative-content-agency",
      "paid advertising": "/creative-content-agency",
      "display ads": "/display-advertising-agency",
      "search ads": "/search-engine-optimization-services",
      "youtube ads": "/branding-consulting-firm",
      "youtube marketing": "/brand-positioning-agency",
      "print design": "/print-design-agency",
      "website design": "/website-development-company",
      "web design": "/website-development-company",
      "web optimization": "/website-development-company",
      "customized website": "/website-development-company",
      "rank keywords": "/search-engine-optimization-services",
      "seo ramking": "/search-engine-optimization-services",
      contact: "/contact",
    };

    // Get the corresponding page based on the input value
    const inputValue = inputData.toLowerCase().trim();

    // Find a matching page based on input
    const matchingPage = Object.keys(pages).find((key) =>
      key.includes(inputValue)
    );

    if (matchingPage) {
      // Redirect to the matched page
      navigate(pages[matchingPage]);
    } else {
      // Navigate to the home page if no match found
      navigate(pages["home"]);
    }
  };

  // Define your Schema.org data
  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Who can get an EV SSL?",
        acceptedAnswer: {
          "@type": "Answer",
          text: 'EV SSL certificates are available to registered companies with "Good Standing" or equivalent status and business entities like partnerships, associations, DBAs, and sole proprietorships.',
        },
      },
      {
        "@type": "Question",
        name: "How will my customers know an Extended Validation SSL protects my site?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Customers will recognize the protection of an Extended Validation SSL by the padlock and HTTPS in the website's address. This indicates encryption, assuring them that their sensitive data, like credit card information, is secure. This fosters confidence in online shopping.",
        },
      },
    ],
  };

  return (
    <>
      <Seo
        title="EV SSL Provider in Bangalore | Hyderabad"
        description="EV SSL provider in bangalore and hyderabad.  Explore how our extended validation ssl delivers robust encryption, rigorous validation, and the prominent green address bar that signals the utmost confidence to your visitors. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="buy ev ssl certificate,
        best ev ssl certificate provider in india"
        searchengines="ALL"
        canonical="https://www.grank.co.in/extented-validation-ssl"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/security/wildcard-banner.png"
            alt="G-Rank"
            title="security"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/security/wildcard-banner-mobile.png"
            alt="G-Rank"
            title="security"
          />

          <div className="security-org-banner-div">
            <h1 className="waviy-sec ">
              <span>
                Extended <br />
                Validation SSL
                <br />
                (EV SSL)
              </span>
            </h1>
            <h2>safety first , always !</h2>

            <h3 onClick={handleOpen}>
              <NavLink to="">Find Out More</NavLink>
            </h3>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ padding: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/web-security-services"
              >
                <p>Security</p>
              </NavLink>

              <Typography color="#000">Extented Validation SSL</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}

        {/* blue text start */}
        <section className="">
          <div className="container">
            <div className="row ">
              <div className="col-12 security-standard-blue-div">
                <motion.h4
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  About Extended Validation (EV) SSL Certificate
                </motion.h4>

                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  An Extended Validation (EV) SSL Certificate is the highest
                  level of SSL security. It requires rigorous verification of a
                  business's legal status, operations, address, phone number,
                  and domain ownership. This certificate offers strong
                  encryption and is essential for online sales and payment
                  processing businesses. Renewal of EV SSL requires biennial
                  re-verification.
                </motion.p>
              </div>
            </div>
          </div>
        </section>
        {/* blue text end  */}
        {/* lock banner start */}

        <section className="security-section-margin">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 security-slider-img">
                <motion.img
                  initial={{ opacity: 0, scale: 0, x: 100 }}
                  whileInView={{ opacity: 1, scale: 1, x: 0 }}
                  transition={{ duration: 0.6 }}
                  className="img-fluid "
                  src="assest/photos/security/extend-ssl-lock-img.png"
                  alt="G-Rank"
                  title="grank"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6 ">
                <motion.h4
                  className="wildcard-lock-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Why should you get an
                  <br />
                  EV SSL Certificate?
                </motion.h4>

                <motion.p
                  className="wildcard-lock-text"
                  initial={{ opacity: 0, scale: 0, y: 100 }}
                  whileInView={{ opacity: 1, scale: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  An EV SSL certificate offers your website
                  <br /> top-tier security, trust, and user confidence. It
                  <br /> features a green address bar, rigorous <br />
                  authentication, phishing prevention, SEO
                  <br /> benefits, customer trust, compliance support, <br />
                  and enhanced brand reputation.
                </motion.p>
              </div>
            </div>
          </div>
        </section>

        {/* lock banner end  */}
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}

        {/* FAQs section start*/}
        <section className="wildcard-lock-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="wildcard-lock-FAQs-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  FAQ's
                </motion.h4>
              </div>
              <div className="col-md-12">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          Who can get an EV SSL?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          EV SSL certificates are available to registered
                          companies with "Good Standing" or equivalent status
                          and business entities like partnerships, associations,
                          DBAs, and sole proprietorships.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          How will my customers know an Extended Validation SSL
                          protects my site?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse show"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Customers will recognize the protection of an Extended
                          Validation SSL by the padlock and HTTPS in the
                          website's address. This indicates encryption, assuring
                          them that their sensitive data, like credit card
                          information, is secure. This fosters confidence in
                          online shopping.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}
        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links For You
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/*Input start */}
        <section>
          <div className="performance-footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/branding/branding-footer-img.png"
              alt="G-Rank"
              title="grank"
            />
            <div className="performance-footer-div-text">
              <h4>Learn more about our sevices</h4>
              <form className="searchArea" onSubmit={handleSubmit}>
                <button type="submit" tabindex="2">
                  Explore
                </button>
                <div className="inputDiv">
                  <input
                    type="text"
                    id="inputData"
                    name="inputData"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    requiredtabindex="1"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
        {/*Input start */}
      </section>
    </>
  );
};

export default ExtentedValidationSSL;
