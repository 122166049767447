import React, { useState, useRef, useEffect } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import Homes from "./branding/Homes";
import Socialmedia from "./social-media/Socialmedia";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import Webdevelop from "./web-develop/Webdevelop";
import InstagramIcon from "@mui/icons-material/Instagram";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import Branding from "./branding/Branding";
import BrandingConsulting from "./branding/BrandingConsulting";
import BrandStrategy from "./branding/BrandStrategy";
import BrandPositioning from "./branding/BrandPositioning";
import BrandIdentity from "./branding/BrandIdentity";
import BrandCommunication from "./branding/BrandCommunication";
import CreativeContent from "./creative&content/CreativeContent";
import DisplayAds from "./creative&content/DisplayAds";
import WebDesign from "./creative&content/WebDesign";
import PrintDesign from "./creative&content/PrintDesign";
import ContentMarketing from "./creative&content/ContentMarketing";
import OrganicMarketing from "./organicmarketing/OrganicMarketing";
import SearchEngineOpt from "./organicmarketing/SearchEngineOpt";
import SocialMediaOpt from "./organicmarketing/SocialMediaOpt";
import PerformanceMarketing from "./performancemarketing/PerformanceMarketing";
import SearchEngineMarketing from "./performancemarketing/SearchEngineMarketing";
import SocialMediaMarketing from "./performancemarketing/SocialMediaMarketing";
import OrganicMarket from "./social-media/OrganicMarket";
import PaidMarketing from "./social-media/PaidMarketing";
import WorkPressDev from "./web-develop/WorkPressDev";
import EcommerceDev from "./web-develop/EcommerceDev";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import DomainAvailable from "./domain/DomainAvailable";
import DomainBooking from "./domain/DomainBooking";
import DomainTransfer from "./domain/DomainTransfer";
import Domain from "./domain/Domains";
import Security from "./security/Security";
import StandardSSL from "./security/StandardSSL";
import WildcardSSL from "./security/WildcardSSL";
import OrganizationValidationSSL from "./security/OrganizationValidationSSL";
import ExtentedValidationSSL from "./security/ExtentedValidationSSL";
import Email from "./email/Email";
import GoogleWorkspace from "./email/GoogleWarkspace";
import Microsoft from "./email/Microsoft";
import RediffMail from "./email/RediffMail";
import Hosting from "./hosting/Hosting";
import SharedHosting from "./hosting/SharedHosting";
import WordPressHosting from "./hosting/WordPress";
import DedicatedHosting from "./hosting/DedicatedHosting";
import VpsHosting from "./hosting/VpsHosting";
import HardWare from "./B2B/HardWare";
import HealthCare from "./B2B/HealthCare";
import Manufacture from "./B2B/Manufactures";
import Power from "./B2B/Power";
import RawMaterial from "./B2B/RawMaterial";
import Tech from "./B2B/Techa";
import Ecommerce from "./B2C/Ecommerce";
import Education from "./B2C/Education";
import InterialFurniture from "./B2C/InterialFurniture";
import Lifestyle from "./B2C/Lifestyle";
import RealEstate from "./B2C/RealEstate";
import B2C from "./B2C/B2C";
import B2B from "./B2B/B2B";
import Automotive from "./B2B/Automotive";
import Blog from "./blog-folder/Blog";
import Career from "./blog-folder/Career";
import CaseStudies from "./blog-folder/CaseStudies";
import Dirak from "./case-studies-pages/Dirak";
import AceForce from "./case-studies-pages/AceForce";
import Ramky from "./case-studies-pages/Ramky";
import EcoLife from "./case-studies-pages/EcoLife";
import Performax from "./case-studies-pages/Performax";
import Shodhana from "./case-studies-pages/Shodhana";
import Cms from "./case-studies-pages/Cms";
import Jayabheri from "./case-studies-pages/Jayabheri";
import Signature from "./case-studies-pages/Signature";
import BlogPagesFirst from "./blog-pages/BlogPagesFirst";
import BlogPagesSecond from "./blog-pages/BlogPagesSecond";
import BlogPagesThird from "./blog-pages/BlogPagesThird";
import BlogPagesFour from "./blog-pages/BlogPagesFour";
import BlogPagesFive from "./blog-pages/BlogPagesFive";
import routes from "../../shared/routes/FrontendRoutes";
import Cart from "../../features/frontend/addtocart/Cart";
import OurCoreTeam from "./our-team/OurCoreTeam";
import OurGallery from "./our-team/OurGallery";
import UserPayment from "./user-payment/UserPayment";
import Register from "../../features/frontend/authentication/Register";
import Contact from "./contact/Contact";
import TeamsCondition from "./blog-folder/TeamsCondition";
import PrivacyPolicy from "./blog-folder/PrivacyPolicy";
import Thankyou from "./blog-folder/Thankyou";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import CustomerContext from "./blog-folder/CustomerContext";
import UtmHandler from "./blog-folder/UtmHandler";
import axios from "axios";
//import Login from "../../features/frontend/authentication/Login";
import PaymentForm from "../../features/frontend/addtocart/PaymentForm";
import PaymentThankyou from "./blog-folder/PaymentThankyou";
import PartnershipCollaboration from "./blog-folder/PartnershipCollaboration";

// accordion section

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const BlankLayout = () => {
  // open dialog section start
  //const [token] = useState("");

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const form = useRef();
  const [openDialog, setOpendialog] = useState(false);
  const [openDialogs, setOpendialogs] = useState(true);
  const [loading, setLoading] = useState(false); // Track loading state

  const handleOpen = () => {
    setOpendialog(true);
  };

  const handleDialogClose = () => {
    setOpendialog(false);
  };
  const handleDialogCloses = () => {
    setOpendialogs(false);
  };

  const isBrandingActive =
    location.pathname === "/branding-agency" ||
    location.pathname === "/branding-consulting-firm" ||
    location.pathname === "/brand-strategy-consultant" ||
    location.pathname === "/brand-positioning-agency" ||
    location.pathname === "/brand-identity-agency" ||
    location.pathname === "/branding-communication-agency";

  const isPerformanceMarketingActive =
    location.pathname === "/performance-marketing-company" ||
    location.pathname === "/search-engine-marketing-agency" ||
    location.pathname === "/social-media-marketing-agency";

  const isOrganicMarketingActive =
    location.pathname === "/organic-marketing-agency" ||
    location.pathname === "/search-engine-optimization-services" ||
    location.pathname === "/social-media-optimization-agency";

  const isCreativeActive =
    location.pathname === "/creative-content-agency" ||
    location.pathname === "/display-advertising-agency" ||
    location.pathname === "/website-development-company" ||
    location.pathname === "/print-design-agency" ||
    location.pathname === "/content-marketing-agency";

  const isSocialMediaActive =
    location.pathname === "/social-media-agency" ||
    location.pathname === "/organic-social-media-agency" ||
    location.pathname === "/paid-marketing-agency";

  const isWebDevelopmentActive =
    location.pathname === "/web-development-agency" ||
    location.pathname === "/wordpress-development-agency" ||
    location.pathname === "/ecommerce-development-agency";

  const [formDataa, setFormDataa] = useState({
    to: "leads@grank.co.in",
    subject: "Enquire From G-Rank",
    message: "",
    name: "",
    phone: "",
    city: "",
    business: "",
    company: "",
    url: "",
    cc: "server@grank.co.in",
    bcc: "",
    email: "", // Add email field to match the backend structure
  });

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const siteKey = "6LcuJ9QmAAAAAGeBZPkD4Am3FwfPo7fJoowZQpvI"; // reCAPTCHA site key

  useEffect(() => {
    // Load reCAPTCHA script
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.async = true;
    document.body.appendChild(script);
  }, [siteKey]);

  useEffect(() => {
    // Update the URL when the component mounts
    setFormDataa((prevData) => ({ ...prevData, url: window.location.href }));
  }, [location]);

  // Handle reCAPTCHA execution and form submission
  const handleSubmitt = async (e) => {
    e.preventDefault();

    setLoading(true); // Set loading state to true

    // Execute reCAPTCHA and get token
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(siteKey, { action: "submit" })
        .then(async (token) => {
          setRecaptchaToken(token);
          console.log("reCAPTCHA token:", token); // Print token to console

          const submissionData = { ...formDataa, token }; // Ensure recaptcha token is included

          try {
            const response = await fetch(
              "https://server.grank.co.in/send-email", // Ensure this is your correct backend URL
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(submissionData),
              }
            );

            if (response.ok) {
              const result = await response.json();
              console.log(result.message);
              localStorage.setItem("userMessage", formDataa.email); // Store email in local storage
              handleDialogClose(); // Close the modal after submission
              navigate("/thank-you");
            } else {
              const errorResult = await response.json();
              console.error("Error:", errorResult.error);
            }
          } catch (error) {
            console.error("Error:", error);
          } finally {
            setLoading(false); // Reset loading state
          }
        })
        .catch((error) => {
          console.error("reCAPTCHA error:", error);
          setLoading(false); // Reset loading state on error
        });
    });
  };

  // Handle input field changes
  const handleChangee = (e) => {
    setFormDataa({ ...formDataa, [e.target.name]: e.target.value });
  };

  // open dialog section end
  // accordion section start
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [expandednest, setExpandednest] = React.useState("panel1");
  const handleChangenest = (panel) => (event, newExpanded) => {
    setExpandednest(newExpanded ? panel : false);
  };
  const [expandednest2, setExpandednest2] = React.useState("panel1");
  const handleChangenest2 = (panel) => (event, newExpanded) => {
    setExpandednest2(newExpanded ? panel : false);
  };
  // accordion section end
  // toggle button section start
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleToggleClose = () => {
    setState(false);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <div className="toggle-icon">
          <NavLink
            style={({ isActive }) => ({
              color: isActive ? "#436fe8" : "#436fe8",
            })}
            to="/"
          >
            <img src="assest/photos/grank.png" alt="G-Rank" title="logo" />
          </NavLink>
          <IconButton
            className="popup-section-icon"
            aria-label="close"
            onClick={handleToggleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 8,
              color: "#000",
            }}
          >
            <CloseIcon style={{ color: "#607D8B" }} />
          </IconButton>
        </div>
        <div>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#fff", color: "#0062cc" }}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography className="accordian-summery">
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to=""
                >
                  About Us
                </NavLink>
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Accordion
                sx={{ borderBottom: "none", borderTop: "none" }}
                expanded={expandednest === "panel13"}
                onChange={handleChangenest("panel13")}
              >
                <AccordionSummary
                  sx={{ backgroundColor: "#fff", color: "#0062cc" }}
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography className="accordian-summery">
                    <NavLink
                      className="accordion-anchor-btn"
                      style={({ isActive }) => ({
                        color: isActive ? "#000f61" : "#436fe8",
                      })}
                      to=""
                    >
                      Industries
                    </NavLink>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion
                    sx={{ borderBottom: "none", borderTop: "none" }}
                    expanded={expandednest2 === "panel13"}
                    onChange={handleChangenest2("panel13")}
                  >
                    <AccordionSummary
                      sx={{ backgroundColor: "#fff", color: "#0062cc" }}
                      aria-controls="panel3d-content"
                      id="panel3d-header"
                    >
                      <Typography className="accordian-summery">
                        <NavLink
                          className="accordion-anchor-btn"
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/b2b-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          B2B
                        </NavLink>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/power-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          Power
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/hardware-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          Hard Ware
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/healthcare-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          HealthCare
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/automotive-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          Automotive
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/tech-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          Tech
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/manufacturing-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          Manufacture
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/raw-material-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          Raw Material
                        </NavLink>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{ borderBottom: "none", borderTop: "none" }}
                    expanded={expandednest2 === "panel14"}
                    onChange={handleChangenest2("panel14")}
                  >
                    <AccordionSummary
                      sx={{ backgroundColor: "#fff", color: "#0062cc" }}
                      aria-controls="panel3d-content"
                      id="panel3d-header"
                    >
                      <Typography className="accordian-summery">
                        <NavLink
                          className="accordion-anchor-btn"
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/b2c-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          B2C
                        </NavLink>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/realestate-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          Real Estate
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/healthcare-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          HealthCare
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/ecommerce-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          E-commerce
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/lifestyle-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          Lifestyle
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/interior-furniture-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          Interial Furniture
                        </NavLink>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#0062cc",
                          paddingLeft: 4,
                          paddingBottom: 1,
                        }}
                      >
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "#000f61" : "#436fe8",
                          })}
                          to="/education-digital-marketing-agency"
                          onClick={handleToggleClose}
                        >
                          Education
                        </NavLink>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/case-studies"
                  onClick={handleToggleClose}
                >
                  Case Studies
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/blog"
                  onClick={handleToggleClose}
                >
                  Blog
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                  borderBottom: "1px solid #ccc",
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/career"
                  onClick={handleToggleClose}
                >
                  Careers
                </NavLink>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              sx={{
                backgroundColor: "#fff",
                color: "#0062cc",
              }}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography sx={{ fontSize: 15 }}>
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/branding-agency"
                  onClick={handleToggleClose}
                >
                  Branding
                </NavLink>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="branding-consulting-firm"
                  onClick={handleToggleClose}
                >
                  Brand Consulting
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/brand-strategy-consultant"
                  onClick={handleToggleClose}
                >
                  Brand Strategy
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/brand-positioning-agency"
                  onClick={handleToggleClose}
                >
                  Brand Position
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/brand-identity-agency"
                  onClick={handleToggleClose}
                >
                  Brand Identity
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                  borderBottom: "1px solid #ccc",
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/branding-communication-agency"
                  onClick={handleToggleClose}
                >
                  Brand Communication
                </NavLink>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#fff", color: "#0062cc" }}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/performance-marketing-company"
                  onClick={handleToggleClose}
                >
                  Performance Marketing
                </NavLink>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="search-engine-marketing-agency"
                  onClick={handleToggleClose}
                >
                  Search Engine Marketing
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                  borderBottom: "1px solid #ccc",
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="social-media-marketing-agency"
                  onClick={handleToggleClose}
                >
                  Social Media Marketing
                </NavLink>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#fff", color: "#0062cc" }}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/organic-marketing-agency"
                  onClick={handleToggleClose}
                >
                  Organic Marketing
                </NavLink>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 3,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="search-engine-optimization-services"
                  onClick={handleToggleClose}
                >
                  Search Engine Optimization
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 3,
                  paddingBottom: 1,
                  // borderBottom: "1px solid #ccc",
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="social-media-optimization-agency"
                  onClick={handleToggleClose}
                >
                  Social Media Optimization
                </NavLink>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#fff", color: "#0062cc" }}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/creative-content-agency"
                  onClick={handleToggleClose}
                >
                  Creative & Content
                </NavLink>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="display-advertising-agency"
                  onClick={handleToggleClose}
                >
                  Display Ads
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="website-development-company"
                  onClick={handleToggleClose}
                >
                  Web Design
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="print-design-agency"
                  onClick={handleToggleClose}
                >
                  Print Design
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                  borderBottom: "1px solid #ccc",
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="content-marketing-agency"
                  onClick={handleToggleClose}
                >
                  Content Marketing
                </NavLink>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#fff", color: "#0062cc" }}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/social-media-agency"
                  onClick={handleToggleClose}
                >
                  Social Media
                </NavLink>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/organic-social-media-agency"
                  onClick={handleToggleClose}
                >
                  Organic Marketing
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                  borderBottom: "1px solid #ccc",
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/paid-marketing-agency"
                  onClick={handleToggleClose}
                >
                  Paid Marketing
                </NavLink>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#fff", color: "#0062cc" }}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography className="accordian-summery">
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/web-development-agency"
                  onClick={handleToggleClose}
                >
                  Web Development
                </NavLink>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/wordpress-development-agency"
                  onClick={handleToggleClose}
                >
                  WordPress Development
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                  borderBottom: "1px solid #ccc",
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/ecommerce-development-agency"
                  onClick={handleToggleClose}
                >
                  Ecommerce Development
                </NavLink>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#fff", color: "#0062cc" }}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography className="accordian-summery">
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/domain"
                  onClick={handleToggleClose}
                >
                  Domain
                </NavLink>
              </Typography>
            </AccordionSummary>
          </Accordion>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#fff", color: "#0062cc" }}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography className="accordian-summery">
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/hosting-service"
                  onClick={handleToggleClose}
                >
                  Hosting
                </NavLink>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/shared-hosting-provider"
                  onClick={handleToggleClose}
                >
                  Shared Hosting
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/wordpress-hosting-services"
                  onClick={handleToggleClose}
                >
                  WordPress Hosting
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/dedicated-hosting-services"
                  onClick={handleToggleClose}
                >
                  Dedicated Hosting
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                  borderBottom: "1px solid #ccc",
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/vps-hosting-services"
                  onClick={handleToggleClose}
                >
                  Vps Hosting
                </NavLink>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#fff", color: "#0062cc" }}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography className="accordian-summery">
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/web-security-services"
                  onClick={handleToggleClose}
                >
                  Security
                </NavLink>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/standard-ssl"
                  onClick={handleToggleClose}
                >
                  Standard SSL
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/organization-validation-ssl"
                  onClick={handleToggleClose}
                >
                  Organization Validation SSL
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                  borderBottom: "1px solid #ccc",
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/extented-validation-ssl"
                  onClick={handleToggleClose}
                >
                  Extented Validation SSL
                </NavLink>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderBottom: "none", borderTop: "none" }}
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#fff", color: "#0062cc" }}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography className="accordian-summery">
                <NavLink
                  className="accordion-anchor-btn"
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/email-hosting-provider"
                  onClick={handleToggleClose}
                >
                  Email
                </NavLink>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  marginLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/google-workspace-service-provider"
                  onClick={handleToggleClose}
                >
                  Google Warkspace
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/microsoft-365-service-provider"
                  onClick={handleToggleClose}
                >
                  Microsoft Office 365
                </NavLink>
              </Typography>
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#0062cc",
                  paddingLeft: 4,
                  paddingBottom: 1,
                  borderBottom: "1px solid #ccc",
                }}
              >
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000f61" : "#436fe8",
                  })}
                  to="/rediff-mail"
                  onClick={handleToggleClose}
                >
                  Rediff Mail
                </NavLink>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </List>
      <div className="toggle-media-section">
        <div className="toggle-media-div">
          <NavLink to="https://www.facebook.com/Grank.India" target="_blank">
            <FacebookIcon className="toggle-media-icon" />
          </NavLink>
          <NavLink to="https://twitter.com/Grank_India/" target="_blank">
            <img
              src="assest/photos/twitter.png"
              alt="G-Rank"
              className="toggle-media-icon "
              title="twitter"
              style={{ height: 25 }}
            />
          </NavLink>
          <NavLink
            to="https://www.linkedin.com/company/g-rank-digital-marketing-company/"
            target="_blank"
          >
            <LinkedInIcon className="toggle-media-icon" />
          </NavLink>
          <NavLink
            to="https://www.youtube.com/channel/UCAuK0EC-TmLLHVSmrjxMnIA"
            target="_blank"
          >
            <YouTubeIcon className="toggle-media-icon" />
          </NavLink>
          <NavLink to="https://www.instagram.com/grank.india/" target="_blank">
            <InstagramIcon className="toggle-media-icon" />
          </NavLink>
        </div>
        <div className="toggle-media-copyright">
          <h2>
            Copyright © {new Date().getFullYear()} G-Rank All Rights Reserved.
          </h2>
        </div>
      </div>
    </Box>
  );
  // toggle section button end

  return (
    <>
      <header className="header1">
        <nav className="navbar navbar-expand-lg navbar-light bg-white p-0">
          <div className="container-fluid menu-inner1">
            <div className="row collapse navbar-collapse">
              <div className="col-md-12 tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <ul className="first-tabs">
                    <li>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="branding-consulting-firm"
                      >
                        Brand Consulting
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="brand-strategy-consultant"
                      >
                        Brand Strategy
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="brand-positioning-agency"
                      >
                        Brand Positioning
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="brand-identity-agency"
                      >
                        Brand Identity
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="branding-communication-agency"
                      >
                        Brand Communication
                      </NavLink>
                    </li>
                  </ul>
                </div>

                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <ul className="first-tabs">
                    <li>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="display-advertising-agency"
                      >
                        Display Ads
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="website-development-company"
                      >
                        Web Design
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="print-design-agency"
                      >
                        Print Design
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="content-marketing-agency"
                      >
                        Content Marketing
                      </NavLink>
                    </li>
                  </ul>
                </div>

                <div
                  className="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <ul className="first-tabs">
                    <li>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="search-engine-optimization-services"
                      >
                        Search Engine Optimization
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="social-media-optimization-agency"
                      >
                        Social Media Optimization
                      </NavLink>
                    </li>
                  </ul>
                </div>

                <div
                  className="tab-pane fade"
                  id="Booking"
                  role="tabpanel"
                  aria-labelledby="booking-tab"
                >
                  <ul className="first-tabs">
                    <li>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="/search-engine-marketing-agency"
                      >
                        Search Engine Marketing
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="/social-media-marketing-agency"
                      >
                        Social-Media Marketing
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div
                  className="tab-pane fade"
                  id="social"
                  role="tabpanel"
                  aria-labelledby="social-tab"
                >
                  <ul className="first-tabs">
                    <li>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="organic-social-media-agency"
                      >
                        Organic Marketing
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="paid-marketing-agency"
                      >
                        Paid Marketing
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div
                  className="tab-pane fade"
                  id="devlopment"
                  role="tabpanel"
                  aria-labelledby="devlopment-tab"
                >
                  <ul className="first-tabs">
                    <li>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="wordpress-development-agency"
                      >
                        WordPress Development
                      </NavLink>
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#fed501" : "#436fe8",
                        })}
                        to="ecommerce-development-agency"
                      >
                        Ecommerce Development
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* navbar2 */}
        <nav className="navbar navbar-expand-lg navbar-light back-ground-color p-0">
          <div className="container-fluid menu-inner">
            <NavLink id="RouterNavLink" to="/">
              <h5 className="navbar-brand" href="#">
                <img
                  src="assest/photos/g-rank-white-logo.png"
                  alt="logo"
                  title="logo"
                />
              </h5>
            </NavLink>
            <Button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent2"
              aria-controls="navbarSupportedContent2"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div className="menuicon-mobile">
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                      {/* {anchor} */}
                      <MenuIcon />
                    </Button>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </div>
            </Button>

            <div className="collapse navbar-collapse">
              <ul className="navbar-nav nav m-auto " id="myTab" role="tablist">
                <li className="nav-item">
                  <span
                    className="nav-link show active"
                    href="#"
                    id="home-tab"
                    data-toggle="tab"
                    data-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected={isBrandingActive}
                  >
                    <img
                      className="nav-link-img"
                      src="assest/photos/black-shadow.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <NavLink
                      style={({ isActive }) => ({
                        color:
                          isActive || isBrandingActive ? "#fed501" : "#fff",
                      })}
                      id="RouterNavLink"
                      to="/branding-agency"
                    >
                      BRANDING
                    </NavLink>
                  </span>
                </li>

                <li className="nav-item ">
                  <span
                    className="nav-link"
                    href="#"
                    id="Booking-tab"
                    data-toggle="tab"
                    data-target="#Booking"
                    type="button"
                    role="tab"
                    aria-controls="Booking"
                    aria-selected={isPerformanceMarketingActive}
                  >
                    <img
                      className="nav-link-img"
                      src="assest/photos/black-shadow.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <NavLink
                      style={({ isActive }) => ({
                        color:
                          isActive || isPerformanceMarketingActive
                            ? "#fed501"
                            : "#fff",
                      })}
                      id="RouterNavLink"
                      to="/performance-marketing-company"
                    >
                      PERFORMANCE MARKETING
                    </NavLink>
                  </span>
                </li>

                <li className="nav-item ">
                  <span
                    className="nav-link"
                    href="#"
                    id="contact-tab"
                    data-toggle="tab"
                    data-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected={isOrganicMarketingActive}
                  >
                    <img
                      className="nav-link-img"
                      src="assest/photos/black-shadow.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <NavLink
                      style={({ isActive }) => ({
                        color:
                          isActive || isOrganicMarketingActive
                            ? "#fed501"
                            : "#fff",
                      })}
                      id="RouterNavLink"
                      to="/organic-marketing-agency"
                    >
                      ORGANIC MARKETING
                    </NavLink>
                  </span>
                </li>

                <li className="nav-item ">
                  <span
                    className="nav-link"
                    href="#"
                    id="profile-tab"
                    data-toggle="tab"
                    data-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="Profile"
                    aria-selected={isCreativeActive}
                  >
                    <img
                      className="nav-link-img"
                      src="assest/photos/black-shadow.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <NavLink
                      style={({ isActive }) => ({
                        color:
                          isActive || isCreativeActive ? "#fed501" : "#fff",
                      })}
                      id="RouterNavLink"
                      to="/creative-content-agency"
                    >
                      CREATIVE & CONTENT
                    </NavLink>
                  </span>
                </li>
                <li className="nav-item ">
                  <span
                    className="nav-link"
                    href="#"
                    id="social-tab"
                    data-toggle="tab"
                    data-target="#social"
                    type="button"
                    role="tab"
                    aria-controls="social"
                    aria-selected={isSocialMediaActive}
                  >
                    <img
                      className="nav-link-img"
                      src="assest/photos/black-shadow.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <NavLink
                      style={({ isActive }) => ({
                        color:
                          isActive || isSocialMediaActive ? "#fed501" : "#fff",
                      })}
                      id="RouterNavLink"
                      to="/social-media-agency"
                    >
                      SOCIAL MEDIA
                    </NavLink>
                  </span>
                </li>
                <li className="nav-item ">
                  <span
                    className="nav-link"
                    href="#"
                    id="devlopment-tab"
                    data-toggle="tab"
                    data-target="#devlopment"
                    type="button"
                    role="tab"
                    aria-controls="devlopment"
                    aria-selected={isWebDevelopmentActive}
                  >
                    <img
                      className="nav-link-img"
                      src="assest/photos/black-shadow.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <NavLink
                      style={({ isActive }) => ({
                        color:
                          isActive || isWebDevelopmentActive
                            ? "#fed501"
                            : "#fff",
                      })}
                      id="RouterNavLink"
                      to="/web-development-agency"
                    >
                      WEB DEVELOPMENT
                    </NavLink>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-expand-lg navbar-light bg-white p-0">
          <div className="menu-inner3">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav nav nav-tabs mr-auto nav-mobile"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item">domain</li>
                <li className="nav-item">hosting</li>
                <li className="nav-item">security</li>
                <li className="nav-item">email</li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <ul className="enquire-button" onClick={handleOpen}>
        <li>
          <span>Enquire Now</span>
          {/*<span className="notification-badge">2</span>*/}
        </li>
      </ul>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle className="popup-section-title">
          <div className="contact-us-form my-0 ">
            <h1>Enquire Now</h1>
          </div>
        </DialogTitle>
        <IconButton
          className="popup-section-icon"
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent className="popup-section">
          <div className="contact-us-form-mobile">
            {/* <h1>Enquire Now</h1> */}

            <form onSubmit={handleSubmitt}>
              <div className="contact-flex">
                <input
                  className="floating-label"
                  placeholder="Name"
                  type="text"
                  name="name"
                  value={formDataa.name}
                  onChange={handleChangee}
                  required
                />
              </div>
              <div className="contact-flex">
                <input
                  placeholder="Phone"
                  type="text"
                  name="phone"
                  value={formDataa.phone}
                  onChange={handleChangee}
                  required
                />
              </div>
              <div className="contact-flex">
                <input
                  placeholder="Company Name"
                  type="text"
                  name="company"
                  value={formDataa.company}
                  onChange={handleChangee}
                />
              </div>
              <div className="contact-flex">
                <select
                  name="business"
                  className="contact-select-div"
                  value={formDataa.business}
                  onChange={handleChangee}
                  required
                >
                  <option value="none">Type Of Business</option>
                  <option value="B2B">B2B</option>
                  <option value="B2C">B2C</option>
                </select>
              </div>
              <div className="contact-flex">
                <input
                  placeholder="City"
                  type="text"
                  name="city"
                  value={formDataa.city}
                  onChange={handleChangee}
                  required
                />
              </div>
              <div className="contact-flex">
                <input
                  placeholder="Email"
                  type="email"
                  name="email" // Ensure email field is included
                  value={formDataa.email}
                  onChange={handleChangee}
                  required
                />
              </div>
              <div className="contact-flex">
                <textarea
                  placeholder="Message"
                  name="message"
                  value={formDataa.message}
                  onChange={handleChangee}
                  required
                />
              </div>
              <h4>
                <button type="submit" disabled={loading}>
                  {loading ? "Submitting..." : "Submit Now"}
                </button>
              </h4>
            </form>
          </div>
        </DialogContent>
      </Dialog>
      {/* disclaimer dialog start */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDialogs}
        onClose={handleDialogCloses}
      >
        <DialogTitle className="popup-section-title">
          <div className="contact-us-form my-0 ">
            <h1>Disclaimer</h1>
          </div>
        </DialogTitle>
        <IconButton
          className="popup-section-icon"
          aria-label="close"
          onClick={handleDialogCloses}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="popup-section-disclaimer">
          <div className="disclaimer-div">
            <h4>
              This website is the only official website of G-Rank Digital
              Services Private Limited. Any other websites or platforms claiming
              our brand name are unauthorized and not affiliated with us.
              <br /> Beware of individuals impersonating our brand "G-Rank"!
              They may use deceptive tactics like phishing emails, spoofed
              websites, or messaging apps to steal your information or carry out
              unauthorized transactions. At G-Rank Digital Services Pvt Ltd, we
              take the security of customers' personal and financial data
              seriously. We only accept payments through RTGS/NEFT, cheques,
              with a valid GST registration by our designated sales heads.
              <br />
              G-Rank Digital Services Pvt Ltd does not engage in any
              unauthorized money-related schemes or promotions. Be cautious of
              any suspicious individuals or offers claiming to be associated
              with us. Beware, we do not have any agents, channel partners,
              part-time jobs, or commission-based revenue models like social
              media engagement & marketing activities claiming to earn revenue.
            </h4>
            <span>Please follow verification standards.</span>
            <h3>Protect Yourself</h3>
            <p>
              Be cautious when sharing personal or financial information online,
              especially in response to suspicious requests.
            </p>
            <h3>Verify Authenticity:</h3>
            <p>
              Double-check the website URL to ensure you're on the official
              G-RANK DIGITAL SERVICES PVT LTD website. Watch out for fraudulent
              sites mimicking our design.
            </p>
            <h3>Secure Your Devices</h3>
            <p>
              Install reputable antivirus software, keep your system updated,
              and scan for malware regularly.
            </p>
            <h3>Phishing Awareness</h3>
            <p>
              We never ask for any sensitive information/data, passwords or
              payment via wire transfer/links in email, messages, calls, Chat
              tools etc., using Telegram, WhatsApp or any other way.
            </p>

            <h3>Stay Informed</h3>
            <p>Educate yourself about common online scams and fraud tactics.</p>
            <br />
            <p>
              By using our services, you acknowledge that G-Rank Digital
              Services Pvt Ltd cannot be held liable for loss, damages, or
              financial scams resulting from unauthorized individuals using our
              brand for fraudulent activities.
            </p>
          </div>
        </DialogContent>
      </Dialog>
      {/* disclaimer dialog start */}
      {/* routes here */}

      <UtmHandler>
        {(utmParams) => (
          <CustomerContext.Provider
            value={{
              handleOpen,
            }}
          >
            <Routes>
              {/* https://servers.myvidya.co.in/ */}
              <Route path="" element={<Homes />} />

              {/* https://servers.myvidya.co.in/branding */}
              <Route path="branding-agency" element={<Branding />} />
              <Route
                path="branding-consulting-firm"
                element={<BrandingConsulting />}
              />
              <Route
                path="brand-strategy-consultant"
                element={<BrandStrategy />}
              />
              <Route
                path="brand-positioning-agency"
                element={<BrandPositioning />}
              />
              <Route path="brand-identity-agency" element={<BrandIdentity />} />
              <Route
                path="branding-communication-agency"
                element={<BrandCommunication />}
              />

              {/* https://servers.myvidya.co.in/creative&content*/}
              <Route
                path="creative-content-agency"
                element={<CreativeContent />}
              />
              <Route
                path="display-advertising-agency"
                element={<DisplayAds />}
              />
              <Route
                path="website-development-company"
                element={<WebDesign />}
              />
              <Route path="print-design-agency" element={<PrintDesign />} />
              <Route
                path="content-marketing-agency"
                element={<ContentMarketing />}
              />

              {/* https://servers.myvidya.co.in/organic-marketing */}
              <Route
                path="organic-marketing-agency"
                element={<OrganicMarketing />}
              />
              <Route
                path="search-engine-optimization-services"
                element={<SearchEngineOpt />}
              />
              <Route
                path="social-media-optimization-agency"
                element={<SocialMediaOpt />}
              />

              {/* https://servers.myvidya.co.in/performance-marketing */}
              <Route
                path="performance-marketing-company"
                element={<PerformanceMarketing />}
              />
              <Route
                path="search-engine-marketing-agency"
                element={<SearchEngineMarketing />}
              />
              <Route
                path="social-media-marketing-agency"
                element={<SocialMediaMarketing />}
              />

              {/* https://servers.myvidya.co.in/social-media */}
              <Route path="social-media-agency" element={<Socialmedia />} />
              <Route
                path="organic-social-media-agency"
                element={<OrganicMarket />}
              />
              <Route path="paid-marketing-agency" element={<PaidMarketing />} />

              {/* https://servers.myvidya.co.in/web-development */}
              <Route path="web-development-agency" element={<Webdevelop />} />
              <Route
                path="wordpress-development-agency"
                element={<WorkPressDev />}
              />
              <Route
                path="ecommerce-development-agency"
                element={<EcommerceDev />}
              />

              {/* https://servers.myvidya.co.in/domain */}
              <Route path="domain" element={<Domain />} />
              <Route path="domain-available" element={<DomainAvailable />} />
              <Route path="domain-booking" element={<DomainBooking />} />
              <Route path="domain-transfer" element={<DomainTransfer />} />

              {/* https://servers.myvidya.co.in/security */}
              <Route path="web-security-services" element={<Security />} />
              <Route path="standard-ssl" element={<StandardSSL />} />
              <Route path="wildcard-ssl" element={<WildcardSSL />} />
              <Route
                path="organization-validation-ssl"
                element={<OrganizationValidationSSL />}
              />
              <Route
                path="extented-validation-ssl"
                element={<ExtentedValidationSSL />}
              />

              {/* https://servers.myvidya.co.in/Hosting */}
              <Route path="hosting-service" element={<Hosting />} />
              <Route
                path="shared-hosting-provider"
                element={<SharedHosting />}
              />
              <Route
                path="wordpress-hosting-services"
                element={<WordPressHosting />}
              />
              <Route
                path="dedicated-hosting-services"
                element={<DedicatedHosting />}
              />
              <Route path="vps-hosting-services" element={<VpsHosting />} />

              {/* https://servers.myvidya.co.in/email */}
              <Route path="email-hosting-provider" element={<Email />} />
              <Route
                path="google-workspace-service-provider"
                element={<GoogleWorkspace />}
              />
              <Route
                path="microsoft-365-service-provider"
                element={<Microsoft />}
              />
              <Route path="rediff-mail" element={<RediffMail />} />

              {/* https://servers.myvidya.co.in/interial-B2B */}

              <Route path="b2b-digital-marketing-agency" element={<B2B />} />
              <Route
                path="hardware-digital-marketing-agency"
                element={<HardWare />}
              />
              <Route
                path="healthcare-digital-marketing-agency"
                element={<HealthCare />}
              />
              <Route
                path="manufacturing-digital-marketing-agency"
                element={<Manufacture />}
              />
              <Route
                path="power-digital-marketing-agency"
                element={<Power />}
              />
              <Route
                path="raw-material-digital-marketing-agency"
                element={<RawMaterial />}
              />
              <Route path="tech-digital-marketing-agency" element={<Tech />} />
              <Route
                path="automotive-digital-marketing-agency"
                element={<Automotive />}
              />

              {/* https://servers.myvidya.co.in/interial-B2C */}
              <Route path="b2c-digital-marketing-agency" element={<B2C />} />
              <Route
                path="ecommerce-digital-marketing-agency"
                element={<Ecommerce />}
              />
              <Route
                path="education-digital-marketing-agency"
                element={<Education />}
              />
              <Route
                path="interior-furniture-digital-marketing-agency"
                element={<InterialFurniture />}
              />
              <Route
                path="lifestyle-digital-marketing-agency"
                element={<Lifestyle />}
              />
              <Route
                path="realestate-digital-marketing-agency"
                element={<RealEstate />}
              />

              {/* https://servers.myvidya.co.in/blog */}
              <Route path="blog" element={<Blog />} />
              <Route path="career" element={<Career />} />
              <Route path="case-studies" element={<CaseStudies />} />
              <Route path="thank-you" element={<Thankyou />} />
              <Route path="payment-thank-you" element={<PaymentThankyou />} />
              <Route
                path="partnership-collaboration"
                element={<PartnershipCollaboration />}
              />

              {/* https://servers.myvidya.co.in/case studies */}
              <Route path="dirak" element={<Dirak />} />
              <Route path="ace-force" element={<AceForce />} />
              <Route path="ramky" element={<Ramky />} />
              <Route path="eco-life" element={<EcoLife />} />
              <Route path="performax" element={<Performax />} />
              <Route path="shodhana" element={<Shodhana />} />
              <Route path="cms" element={<Cms />} />
              <Route path="jayabheri" element={<Jayabheri />} />
              <Route path="signature" element={<Signature />} />

              {/* https://servers.myvidya.co.in/blog */}
              <Route
                path="top-real-estate-digital-marketing-tips"
                element={<BlogPagesFirst />}
              />
              <Route path="blog-second" element={<BlogPagesSecond />} />
              <Route path="blog-third" element={<BlogPagesThird />} />
              <Route path="blog-four" element={<BlogPagesFour />} />
              <Route path="blog-five" element={<BlogPagesFive />} />
              <Route path="terms-conditions" element={<TeamsCondition />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />

              {/* https://servers.myvidya.co.in/cart */}
              <Route path="cart" element={<Cart />} />

              {/* https://servers.myvidya.co.in/contact */}
              <Route
                path="contact"
                element={<Contact utmParams={utmParams} />}
              />

              {/* https://servers.myvidya.co.in/ourteam */}
              <Route path="our-team" element={<OurCoreTeam />} />
              <Route path="our-gallery" element={<OurGallery />} />
              <Route path="user-payment" element={<UserPayment />} />
              <Route path="/register" element={<Register />} />
              <Route path="/Payment-Form" element={<PaymentForm />} />
            </Routes>
            <Routes>
              {Array.isArray(routes) &&
                routes
                  .filter(({ addRoute }) => addRoute)
                  .map(({ path, component }, i) => (
                    <Route key={path + i} path={path} element={component} />
                  ))}
            </Routes>
          </CustomerContext.Provider>
        )}
      </UtmHandler>

      {/* routes here */}

      {/* footer part */}

      <footer id="pt-footer">
        <div className="pt-footer-style-1">
          <div className="footer-back">
            <div className="container py-4">
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <div className="widget">
                    <h4 className="footer-title">Our Products</h4>
                    <div className="menu-our-products-container">
                      <ul id="menu-our-products" className="menu">
                        <li
                          id="menu-item-2195"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2195"
                        >
                          <NavLink to="/branding-agency">Branding</NavLink>
                        </li>
                        <li
                          id="menu-item-2196"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2196"
                        >
                          <NavLink to="/performance-marketing-company">
                            Performance Marketing
                          </NavLink>
                        </li>
                        <li
                          id="menu-item-2197"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2197"
                        >
                          <NavLink to="/organic-marketing-agency">
                            Organic Marketing
                          </NavLink>
                        </li>
                        <li
                          id="menu-item-2198"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2198"
                        >
                          <NavLink to="/creative-content-agency">
                            Creative & Content
                          </NavLink>
                        </li>
                        <li
                          id="menu-item-2199"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2199"
                        >
                          <NavLink to="/social-media-agency">
                            Social Media
                          </NavLink>
                        </li>
                        <li
                          id="menu-item-12941"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-12941"
                        >
                          <NavLink to="/web-development-agency">
                            Web Development
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <div className="widget">
                    <h4 className="footer-title">About G-rank</h4>

                    <div className="menu-about-mrcretive-container">
                      <ul id="menu-about-mrcretive" className="menu">
                        <li
                          id="menu-item-3920"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3920"
                        >
                          <NavLink to="/case-studies">Case Studies</NavLink>
                        </li>
                        <li
                          id="menu-item-3921"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3921"
                        >
                          <NavLink to="/career">Careers</NavLink>
                        </li>

                        <li
                          id="menu-item-3923"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3923"
                        >
                          <NavLink to="/contact">Contact</NavLink>
                        </li>
                        <li
                          id="menu-item-3923"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3923"
                        >
                          <NavLink to="/blog">Blogs</NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <div className="widget">
                    <h4 className="footer-title">Hyderabad</h4>
                    <div className="row">
                      <div className="col-sm-12">
                        <ul className="pt-contact">
                          <li>
                            <LocationOnIcon />
                            <span>
                              1-8-382, Ground Floor, Queen’s Plaza,
                              Begumpet,Hyderabad – 500003
                            </span>
                          </li>
                          <li>
                            <a href="tel:+918121826992">
                              <CallIcon />
                              <span>+91 8121826992</span>
                            </a>
                          </li>
                          <li>
                            <a href="mailto:leads@grank.co.in">
                              <MailIcon />
                              <span>leads@grank.co.in</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="widget">
                    <div className="wp-block-columns">
                      <div className="wp-block-column"></div>
                      <div className="wp-block-column"></div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <div className="widget">
                    <h4 className="footer-title">Bangalore</h4>
                    <div className="row">
                      <div className="col-sm-12">
                        <ul className="pt-contact">
                          <li>
                            <LocationOnIcon />
                            <span>
                              2nd Floor, R K Lake Tower, Service Road, Subramani
                              Nagar, Guddadahalli, Hebbal, Bengaluru,
                              Karnataka-560024
                            </span>
                          </li>
                          <li>
                            <a href="tel:+919686442800">
                              <CallIcon />
                              <span>+91 9686442800</span>
                            </a>
                          </li>
                          <li>
                            <a href="mailto:leads@grank.co.in">
                              <MailIcon />
                              <span>leads@grank.co.in</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="widget">
                    <div className="wp-block-columns">
                      <div className="wp-block-column"></div>
                      <div className="wp-block-column"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-back2">
            <div className=" footer-white-bg-padding">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-4 col-md-4 col-lg-4">
                          <img
                            src="assest/photos/google-partner-big.png"
                            alt="G-Rank"
                            className="img-fluid footer-icon-img"
                            title="google partner"
                          />
                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                          <img
                            src="assest/photos/meta-partner-big.png"
                            alt="G-Rank"
                            className="img-fluid footer-icon-img2"
                            title="meta partner"
                          />
                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                          <img
                            src="assest/photos/google-workspace-big.png"
                            alt="G-Rank"
                            className="img-fluid footer-icon-img"
                            title="goole-workspace"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-4 col-md-4 col-lg-4">
                          <img
                            src="assest/photos/google-cloud-big.png"
                            alt="G-Rank"
                            className="img-fluid footer-icon-img2"
                            title="google cloud"
                          />
                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                          <img
                            src="assest/photos/rediff-big.png"
                            alt="G-Rank"
                            className="img-fluid footer-icon-img"
                            title="rediff"
                          />
                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                          <img
                            src="assest/photos/microsoft-logo.png"
                            alt="G-Rank"
                            className="img-fluid footer-icon-img"
                            title="microsoft"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 mt50">
                  <div className="widget">
                    <div className="row">
                      <div className="col-sm-12 text-align-div">
                        <img
                          src="assest/photos/g-rank-white-logo.png"
                          className="pt-footer-logo"
                          alt="gym-footer-logo"
                          title="logo"
                        />
                        <p className="grank-title">
                          G-Rank - Digital Marketing Partner an ISO 9001:2008
                          certified company, established in 2010, is leading
                          Digital Marketing company. We strategize and deliver
                          pertinent digital marketing campaigns that adds value
                          to the client`s bottom line. We are a proud holder of
                          Google Certified Partnership since 2011.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-back3"></div>

          <div className="pt-copyright-footer">
            <div className="container">
              <div className="row">
                <div
                  className="col-12 col-md-5 col-lg-5 align-self-center"
                  id="order2"
                >
                  <span className="pt-copyright">
                    <NavLink to="">
                      Copyright © {new Date().getFullYear()} G-Rank All Rights
                      Reserved.
                    </NavLink>
                  </span>
                </div>
                <div
                  className="col-12 col-md-2 col-lg-4 align-self-center terms-policy-align"
                  id="order2"
                >
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#fff" : "#fff",
                    })}
                    className="teams-link"
                    to="/terms-conditions"
                  >
                    Terms & Conditions
                  </NavLink>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#fff" : "#fff",
                    })}
                    className="teams-link"
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </NavLink>
                </div>

                <div className="col-12 col-md-5 col-lg-3" id="order1">
                  <div className="pt-footer-social">
                    <ul>
                      <a
                        href="https://www.youtube.com/channel/UCAuK0EC-TmLLHVSmrjxMnIA"
                        target="_blank"
                      >
                        <li>
                          <IconButton color="primary">
                            <YouTubeIcon />
                          </IconButton>
                        </li>
                      </a>
                      <a
                        href="https://www.facebook.com/Grank.India"
                        target="_blank"
                      >
                        <li>
                          <IconButton color="primary">
                            <FacebookIcon />
                          </IconButton>
                        </li>
                      </a>
                      <a
                        href="https://www.instagram.com/grank.india/"
                        target="_blank"
                      >
                        <li>
                          <IconButton color="primary">
                            <InstagramIcon />
                          </IconButton>
                        </li>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/g-rank-digital-marketing-company/"
                        target="_blank"
                      >
                        <li>
                          <IconButton color="primary">
                            <LinkedInIcon />
                          </IconButton>
                        </li>
                      </a>
                      <a
                        href="https://twitter.com/Grank_India/"
                        target="_blank"
                      >
                        <li>
                          <IconButton color="primary">
                            <XIcon />
                          </IconButton>
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default BlankLayout;
