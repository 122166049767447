import React from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import Seo from "../seo/Seo";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";
const BrandPositioning = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const settings3 = {
    // dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Grank",
    url: "https://www.grank.co.in/",
    potentialAction: {
      "@type": "SearchAction",
      target:
        "https://www.grank.co.in/brand-positioning-agency{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  return (
    <>
      <Seo
        title="Brand Positioning Agency In India - G-Rank"
        description="G-Rank, a distinguished brand positioning firm in India, offers expert solutions to strengthen your brand and drive business growth through strategic positioning."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="brand positioning agency,
        best brand positioning agency in Hyderabad,
        best brand positioning agency in bangalore,
        top brand positioning in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/brand-positioning-agency"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        <div className="branding-consulting-banner">
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/branding/brand-position-banner-small.png"
            alt="G-Rank"
            title="brand position"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/branding/brand-position-banner-small-mobile.png"
            alt="G-Rank"
            title="brand position"
          />
          <div className="branding-cunsulting-banner-new-div">
            {/* <h1 data-text="Brand positioning agency"> */}
            <h1>
              Brand
              <br />
              Positioning Agency
            </h1>
            <h2>Turning Visions into Brand Reality</h2>

            <motion.h3
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Get Started</NavLink>
            </motion.h3>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#ccc",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#ccc",
                })}
                to="/branding-agency"
              >
                <p>Branding</p>
              </NavLink>

              <Typography color="text.primary">
                Branding Positioning Agency
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="branding-process-div">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12 col-md-7 col-lg-6 ">
                <img
                  className="img-fluid branding-pro-img desktop-none"
                  src="assest/photos/branding/automation.png"
                  alt="G-Rank"
                  title="brand position"
                />
                <div id="level">
                  <div id="content">
                    <div id="gears">
                      <div id="gears-static"></div>
                      <div id="gear-system-1">
                        <div className="shadow" id="shadow15"></div>
                        <div id="gear15"></div>
                        <div className="shadow" id="shadow14"></div>
                        <div id="gear14"></div>
                        <div className="shadow" id="shadow13"></div>
                        <div id="gear13"></div>
                      </div>
                      <div id="gear-system-2">
                        <div className="shadow" id="shadow10"></div>
                        <div id="gear10"></div>
                        <div className="shadow" id="shadow3"></div>
                        <div id="gear3"></div>
                      </div>
                      <div id="gear-system-3">
                        <div className="shadow" id="shadow9"></div>
                        <div id="gear9"></div>
                        <div className="shadow" id="shadow7"></div>
                        <div id="gear7"></div>
                      </div>
                      <div id="gear-system-4">
                        <div className="shadow" id="shadow6"></div>
                        <div id="gear6"></div>
                        <div id="gear4"></div>
                      </div>
                      <div id="gear-system-5">
                        <div className="shadow" id="shadow12"></div>
                        <div id="gear12"></div>
                        <div className="shadow" id="shadow11"></div>
                        <div id="gear11"></div>
                        <div className="shadow" id="shadow8"></div>
                        <div id="gear8"></div>
                      </div>
                      <div className="shadow" id="shadow1"></div>
                      <div id="gear1"></div>
                      <div id="gear-system-6">
                        <div className="shadow" id="shadow5"></div>
                        <div id="gear5"></div>
                        <div id="gear2"></div>
                      </div>
                      <div className="shadow" id="shadowweight"></div>
                      <div id="chain-circle"></div>
                      <div id="chain"></div>
                      <div id="weight"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5 col-lg-6 branding-consulting-process-text">
                <motion.h4
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Our
                </motion.h4>
                <motion.h2
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Process
                </motion.h2>

                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  At G-Rank, we follow a strategic process for brand
                  positioning. Our approach is designed to help businesses
                  establish a strong online presence and effectively communicate
                  their brand message to target audiences. We conduct
                  comprehensive research to identify the unique selling
                  propositions and key messages that will differentiate the
                  brand in the digital space.
                </motion.p>
              </div>
            </div>
          </div>
        </div>
        {/* action black start*/}
        <div className="action-black-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch with Our Brand Positioning Firm Today
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-black-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action black end*/}
        {/* what we do start */}
        <div className="container-fluid branding-consulting-what">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-7 " id="order2">
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                className=" branding-consulting-what-heading"
              >
                <h4>Why to Choose Our Brand Positioning Services</h4>
              </motion.div>
              <div className="branding-consulting-what-text">
                <p>
                  <span>Uncover Your Unique Attributes :</span>
                  Our team works with you to identify your brand's unique
                  selling points, and what sets you apart from the competition.
                </p>
                <p>
                  <span>Develop Winning Strategies : </span>
                  Based on your unique attributes and target audience, we
                  develop customised positioning strategies that align with your
                  business goals.
                </p>
                <p>
                  <span>Craft a Compelling Brand Story :</span>
                  We help you tell your brand story in a way that is both
                  informative and engaging, capturing the imagination of your
                  target audience.
                </p>
                <p>
                  <span>Create a Unified Brand Image :</span>
                  We ensure a consistent brand image across all touchpoints,
                  from your logo and messaging to your marketing materials and
                  customer interactions.
                </p>
                <p>
                  <span>Market Research :</span>
                  We conduct in-depth market research to understand your
                  industry, competitors, and target audience.
                </p>
                <p>
                  <span>Brand Analysis :</span>
                  We analyse your existing brand identity and identify areas for
                  improvement.
                </p>
                <p>
                  <span>Strategy Development :</span>
                  We work collaboratively with you to develop a positioning
                  strategy that captures the essence of your brand and resonates
                  with your audience.
                </p>
                <p>
                  <span>Implementation and Measurement :</span>
                  We help you implement your brand positioning strategy and
                  track its effectiveness.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5" id="order1">
              <motion.img
                initial={{ opacity: 0, scale: 0, y: 200 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="img-fluid branding-consulting-process-img"
                src="assest/photos/branding/what-we-do-img-blue3.png"
                alt="G-Rank"
                title="grank"
              />
            </div>
          </div>
        </div>
        {/* what we do end */}
        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="branding-quick-link"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links
                <p className="d-block"> For You</p>
              </motion.h2>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        <img
          className="img-fluid w-100"
          src="assest/photos/branding/branding-footer-img.png"
          alt="G-Rank"
          title="grank"
        />
      </section>
    </>
  );
};

export default BrandPositioning;
