import React, { useState, useEffect } from "react";
import Header from "../header2/Header";
import Seo from "../seo/Seo";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

const Career = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    to: "leads@grank.co.in",
    subject: "Enquire From G-Rank",
    name: "",
    email: "",
    phone: "",
    designation: "",
    city: "",
    message: "",
    cc: "server@grank.co.in",
    bcc: "",
  });

  const [file, setFile] = useState(null); // Separate state for file
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [loading, setLoading] = useState(false); // Track loading state

  const siteKey = "6LcuJ9QmAAAAAGeBZPkD4Am3FwfPo7fJoowZQpvI"; // reCAPTCHA site key

  useEffect(() => {
    // Load reCAPTCHA script
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.async = true;
    document.body.appendChild(script);
  }, [siteKey]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); // Set loading state to true

    // Execute reCAPTCHA and get token
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(siteKey, { action: "submit" })
        .then(async (token) => {
          setRecaptchaToken(token);
          console.log("reCAPTCHA token:", token); // Print token to console

          // Create FormData object for file upload
          const formDataObj = new FormData();
          for (const key in formData) {
            formDataObj.append(key, formData[key]);
          }

          if (file) {
            formDataObj.append("filee", file); // Append file to formDataObj
          }
          formDataObj.append("token", token); // Append reCAPTCHA token

          try {
            const response = await fetch(
              "https://server.grank.co.in/send-email2", // Ensure this is your correct backend URL
              {
                method: "POST",
                body: formDataObj,
              }
            );

            if (response.ok) {
              const result = await response.json();
              console.log(result.message);
              navigate("/thank-you");
            } else {
              const errorResult = await response.json();
              console.error("Error:", errorResult.error);
            }
          } catch (error) {
            console.error("Error:", error);
          } finally {
            setLoading(false); // Reset loading state
          }
        })
        .catch((error) => {
          console.error("reCAPTCHA error:", error);
          setLoading(false); // Reset loading state on error
        });
    });
  };

  // Handle input field changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFile(files[0]); // Set file for upload
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Grank",
    url: "https://www.grank.co.in/career",
    logo: "https://www.grank.co.in/assest/photos/g-rank-white-logo.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "8522953903",
      contactType: "",
      areaServed: "IN",
      availableLanguage: "en",
    },
    sameAs: [
      "https://www.facebook.com/Grank.India",
      "https://twitter.com/Grank_India/",
      "https://www.instagram.com/grank.india/",
      "https://www.youtube.com/channel/UCAuK0EC-TmLLHVSmrjxMnIA",
      "https://www.linkedin.com/company/g-rank-digital-marketing-company/",
    ],
  };

  return (
    <>
      <Seo
        title="Careers | G-Rank"
        description="Explore our Career webpage to discover exciting opportunities, a supportive work environment, and a chance to be part of a dynamic team."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="react, meta tags, seo"
        searchengines="ALL"
        canonical="https://www.grank.co.in/career"
        schema={schema}
      />
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 career-img-banner "
            src="assest/photos/career/career-banner.png"
            alt="G-Rank"
            title="career"
          />
          <div className="career-banner-div">
            <h2 className="waviy ">
              <span style={{ color: "#fff" }}>Hello there!</span>
            </h2>
            <motion.h3
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1.2 }}
            >
              If you have it in you to be a change-maker, team player and
              impactful contributor, you are welcome.
            </motion.h3>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{
                paddingTop: "40px",
                display: "flex",
                justifyContent: "center",
                color: "#fff",
              }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <Typography color="#fff">Careers</Typography>
            </Breadcrumbs>
          </div>
          <div className="career-yellow-div">
            <h2>What we look for in you</h2>
          </div>
        </div>
        {/* banner end */}
        {/* second-section start */}
        <section className="career-second-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 career-second-div">
                <motion.img
                  className="img-fluid"
                  src="assest/photos/career/career-icon1.png"
                  alt="G-Rank"
                  title="career"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                />
                <h3>People Skills</h3>
                <p>
                  We seek individuals with excellent interpersonal skills,
                  capable of building strong relationships within the team.
                </p>
              </div>
              <div className="col-12 col-md-4 col-lg-4 career-second-div">
                <motion.img
                  className="img-fluid"
                  src="assest/photos/career/career-icon2.png"
                  alt="G-Rank"
                  title="career"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                />
                <h3>Growth Mindset</h3>
                <p>
                  We look for individuals who are eager to learn and adapt,
                  contributing to their personal and professional growth.
                </p>
              </div>
              <div className="col-12 col-md-4 col-lg-4 career-second-div">
                <motion.img
                  className="img-fluid"
                  src="assest/photos/career/career-icon3.png"
                  alt="G-Rank"
                  title="career"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                />
                <h3>Team Player</h3>
                <p>
                  We value collaboration and the ability to work effectively
                  with others towards common goals.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* second-section end */}
        <section className="career-form-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="contact-us-form">
                  <h1 className="apnow">Join Us Now</h1>
                  <form onSubmit={handleSubmit}>
                    <div className="contact-flex">
                      <input
                        className="floating-label"
                        placeholder="Name"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Email"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Phone"
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Designation"
                        type="text"
                        name="designation"
                        value={formData.designation}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="contact-flex">
                      <select
                        name="city"
                        className="contact-select-div"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      >
                        <option value="none">Preferred Location</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Bangalore">Bangalore</option>
                      </select>
                    </div>

                    <div className="contact-flex">
                      <textarea
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="contact-flex">
                      <input
                        type="file"
                        className="sdupload"
                        placeholder="Upload File"
                        name="filee"
                        onChange={handleChange}
                      />
                    </div>
                    <h4>
                      <button type="submit" disabled={loading}>
                        {loading ? "Applying..." : "Apply Now"}
                      </button>
                    </h4>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Career;
