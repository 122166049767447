import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Header from "../header2/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PaymentThankyou = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const myData = localStorage.getItem("loggedUser"); // Declare myData before use

  const loadUsers = () => {
    axios
      .get("https://server.grank.co.in/get-payment-detail")
      .then((response) => {
        setUsers(response?.data);
      })
      .catch((err) => {
        console.error("Error fetching users:", err);
      });
  };

  useEffect(() => {
    if (users.length === 0 && myData) {
      loadUsers();
    }
  }, [users, myData]);

  //Filtering users by email
  useEffect(() => {
    if (users.length > 0 && myData) {
      const filteredUser = users.find((user) => user.email === myData);
      if (filteredUser) {
        setSelectedUser(filteredUser);
      }
    }
  }, [users, myData]);

  console.log("PaymentselectedUser", selectedUser);

  return (
    <>
      <section className="section-top">
        <Header />
        {selectedUser && (
          <div className="container-fluid thankyou-div">
            <h1>
              Thank You! {selectedUser.firstname}, your {selectedUser.error}
            </h1>
            <h2>
              We have received your payment of
              <span className="payment-amoun">
                <img
                  className="img-fluid paymeny-amount-img"
                  src="assest/photos/rupee3.png"
                  alt="G-Rank"
                />
                {selectedUser.amount}
              </span>
              successfully...
            </h2>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/")}
            >
              Back to Home
            </Button>
          </div>
        )}
      </section>
    </>
  );
};

export default PaymentThankyou;
