import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Header from "../header2/Header";
import { useNavigate } from "react-router-dom";

const Thankyou = () => {
  const navigate = useNavigate();
  const navi = () => {
    navigate("/");
  };
  const [userMessage, setUserMessage] = useState("");

  useEffect(() => {
    // Retrieve the message from localStorage or set a default message
    const storedMessage = localStorage.getItem("userMessage");
    setUserMessage(storedMessage || "Thank you for your submission!");

    // Add the Google Ads conversion script
    const script = document.createElement("script");
    script.innerHTML = `
      gtag('event', 'conversion', {'send_to': 'AW-16732391142/dagjCN_ritsZEOaN0Ko-'});
      console.log("Google Ads conversion script has been executed");
    `;
    document.head.appendChild(script);

    return () => {
      // Cleanup the script when component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <section className="section-top">
        <Header />
        <div className="container-fluid thankyou-div">
          <h1>Thank You! {userMessage}</h1>
          <h2>
            Thank you for your interest! Our representative will call you back
            shortly!!
          </h2>
          <Button variant="contained" color="primary" onClick={navi}>
            Back to Home
          </Button>
        </div>
      </section>
    </>
  );
};

export default Thankyou;
