import React from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import Seo from "../seo/Seo";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";

const WorkPressDev = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings11 = {
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3.5,
    autoplay: true,
    speed: 500,

    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Grank",
    url: "https://www.grank.co.in/",
    potentialAction: {
      "@type": "SearchAction",
      target:
        "https://www.grank.co.in/wordpress-development-agency{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  return (
    <>
      <Seo
        title="Wordpress Development Agency In India - G-Rank"
        description="Looking for a top-notch WordPress website development company? G-Rank offers innovative solutions to enhance your website."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="react, meta tags, seo"
        searchengines="ALL"
        canonical="https://www.grank.co.in/wordpress-development-agency"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/web-development/web-dev-banner.png"
            alt="G-Rank"
            title="web Development"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/web-development/web-dev-banner-mobile.png"
            alt="G-Rank"
            title="web Development"
          />

          <div className="web-develop-banner-div">
            <h1 className="waviy2">
              <span>W</span>
              <span>o</span>
              <span>r</span>
              <span>d</span>
              <span>p</span>
              <span>r</span>
              <span>e</span>
              <span>s</span>
              <span>s</span>
              <br />
              <span>D</span>
              <span>e</span>
              <span>v</span>
              <span>e</span>
              <span>l</span>
              <span>o</span>
              <span>p</span>
              <span>m</span>
              <span>e</span>
              <span>n</span>
              <span>t</span>
              <br />
              <span>A</span>
              <span>g</span>
              <span>e</span>
              <span>n</span>
              <span>c</span>
              <span>y</span>
            </h1>
            <h2>
              Drive excellence through
              <br />
              creativity
            </h2>

            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">GET STARTED</NavLink>
            </motion.h3>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              // style={{ padding: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/web-development-agency"
              >
                <p>Web Development</p>
              </NavLink>

              <Typography color="#fff">WordPress Development Agency</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* section2 Start */}
        <section className="web-section2">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <img
                  className="img-fluid w-100"
                  src="assest/photos/web-development/web-section2-img1.png"
                  alt="G-Rank"
                  title="web Development"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-12">
                    <motion.h4
                      className="section2-web-develop-header"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      What Differentiates Our WordPress Website Development
                      Company?
                    </motion.h4>
                    <div className="section2-web-develop-passage">
                      <p>
                        <span>Personal Touch : </span>We take the time to
                        understand your unique needs and vision, ensuring your
                        website reflects your brand.
                      </p>
                      <p>
                        <span>Tailored Solutions : </span>Instead of
                        one-size-fits-all, we create custom designs and features
                        that cater to your business goals.
                      </p>
                      <p>
                        <span>Ongoing Support : </span>We don’t just hand over
                        the site; we offer continued support and maintenance, so
                        you always have a helping hand.
                      </p>
                      <p>
                        <span>Fast Turnaround : </span>We respect your time, so
                        we work efficiently to deliver your project without
                        compromising quality.
                      </p>
                      <p>
                        The above features distinguish G-Rank from other
                        WordPress Development Companies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section2 end */}
        {/* what we do start */}
        <div className="container-fluid branding-sem-consulting-what creative-what-we">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-7 " id="order2">
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                className=" branding-sem-consulting-what-heading"
              >
                <h4>What Our WordPress Development Services Company Do?</h4>
              </motion.div>
              <div className="branding-sem-consulting-what-text">
                <p>Create custom WordPress websites</p>
                <p>Modify themes to match brand identity</p>
                <p>Integrate plugins to add functionality</p>
                <p>Ensure websites are responsive across devices</p>
                <p>Set up and organize site content</p>
                <p>Implement SEO best practices for better visibility</p>
                <p>Optimize page speed for fast loading times</p>
                <p>Implement security plugins and measures</p>
                <p>Set up regular data backups for safety</p>
                <p>Code custom features as required</p>
                <p>Test and debug to ensure site functionality</p>
                <h2>
                  Wordpress development services in hyderabad and bangalore
                </h2>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5 " id="order1">
              <motion.img
                initial={{ opacity: 0, scale: 0, y: 200 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="img-fluid branding-consulting-process-img"
                src="assest/photos/branding/what-we-do-img-yellow2.png"
                alt="G-Rank"
                title="grank"
              />
            </div>
          </div>
        </div>
        {/* what we do end */}

        {/* web-sliider section start */}
        <div className="container-fluid slider-icon-div">
          <div className="row ">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                our process
              </motion.h4>
            </div>
            <div className="col-12 web-slider-div">
              <Slider {...settings11}>
                <div>
                  <div className="web-text-slider slider-margin-top">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/wordpress-icon/wordpress-icon1.png"
                      alt="G-Rank"
                      title="web Development"
                    />
                    <h4>SEO-Friendly Setup</h4>
                    <p>
                      Configure SEO plugins for on-page optimization.
                      <br />
                      <br />
                      Optimize site structure, URLs, and meta tags.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider ">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/wordpress-icon/wordpress-icon2.png"
                      alt="G-Rank"
                      title="web Development"
                    />
                    <h4>
                      Responsive Theme <br />
                      Selection
                    </h4>
                    <p>
                      Choose a responsive WordPress theme for mobile-friendly
                      experience.
                      <br />
                      <br />
                      Ensure compatibility across various devices and screen
                      sizes.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider slider-margin-top">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/wordpress-icon/wordpress-icon3.png"
                      alt="G-Rank"
                      title="web Development"
                    />
                    <h4>
                      Customization for
                      <br /> User Experience
                    </h4>
                    <p>
                      Tailor design and layout for intuitive user navigation.
                      <br />
                      <br />
                      Prioritize clear CTAs and strategic placement.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider ">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/wordpress-icon/wordpress-icon4.png"
                      alt="G-Rank"
                      title="web Development"
                    />
                    <h4>
                      Speed and Performance <br /> Optimization
                    </h4>
                    <p>
                      Optimize images, use caching plugins, and minimize code.
                      <br />
                      <br />
                      Enhance site loading speed for better user experience.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider slider-margin-top">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/wordpress-icon/wordpress-icon5.png"
                      alt="G-Rank"
                      title="web Development"
                    />
                    <h4>
                      Analytics <br />
                      Integration
                    </h4>
                    <p>
                      Integrate analytics tools (e.g., Google Analytics) for
                      tracking.
                      <br />
                      Set up goals and events to measure conversions.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider ">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/wordpress-icon/wordpress-icon6.png"
                      alt="G-Rank"
                      title="web Development"
                    />
                    <h4>
                      Social Media <br />
                      Integration
                    </h4>
                    <p>
                      Add social sharing buttons and feeds.
                      <br />
                      <br />
                      Optimize content for easy sharing on social platforms.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider slider-margin-top">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/wordpress-icon/wordpress-icon7.png"
                      alt="G-Rank"
                      title="web Development"
                    />
                    <h4>
                      Security and <br />
                      Compliance
                    </h4>
                    <p>
                      Implement security measures (SSL, firewalls) for user
                      trust.
                      <br />
                      <br />
                      Ensure compliance with data privacy regulations.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {/* web-sliider section end  */}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links For You
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/* action yellow start*/}
        <div className="web-dev-action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Let’s Work Together
            <span className="d-block">
              With A Top WordPress Development Agency
            </span>
          </motion.p>
          <NavLink to="">
            <motion.p
              className="web-dev-action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Get Started
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}
      </section>
    </>
  );
};

export default WorkPressDev;
