import React, { useState, useEffect } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
//import AddIcon from "@mui/icons-material/Add";
//import RemoveIcon from "@mui/icons-material/Remove";
//import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
//import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Seo from "../seo/Seo";
import { NavLink, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";
import { loadProducts } from "../../../features/frontend/addtocartgoogle/thunk-productgoogle";
import { useDispatch } from "react-redux";
import AddToCartGoogles from "../../../features/frontend/addtocartgoogle/AddToCartGoogles";
const GoogleWorkspace = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProducts());
  }, [dispatch]);
  // useState section start--------------------------------------

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  //  useState section end----------------------------------------
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings4 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // -------------------
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",
      "domain book": "/domain-booking",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "branding consulting firm": "/branding-consulting-firm",
      "branding strategy consultant": "/brand-strategy-consultant",
      "brand positioning agency": "/brand-positioning-agency",
      "brand identity agency": "/brand-identity-agency",
      "branding communication agency": "/branding-communication-agency",
      "performance marketing company": "/performance-marketing-company",
      "social media marketing agency": "/social-media-marketing-agency",
      "search engine marketing agency": "/search-engine-marketing-agency",
      "organic marketing agency": "/organic-marketing-agency",
      "search engine optimization services":
        "/search-engine-optimization-services",
      "search engine optimization": "/search-engine-optimization-services",
      sem: "/search-engine-optimization-services",
      "social media optimization agency": "/social-media-optimization-agency",
      "creative content agency": "/creative-content-agency",
      "display advertising agency": "/display-advertising-agency",
      "website development company": "/website-development-company",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
      "seo services": "/search-engine-optimization-services",
      seo: "/search-engine-optimization-services",
      "web development": "/web-development-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "smm service": "/paid-marketing-agency",
      smm: "/paid-marketing-agency",
      "social media marketing": "/paid-marketing-agency",
      "social media optimization": "/organic-social-media-agency",
      "smo service": "/organic-social-media-agency",
      smo: "/organic-social-media-agency",
      "content marketing": "/content-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "brand marketing": "/branding-agency",
      "facebook optimization": "/social-media-agency",
      "instagram optimization": "/social-media-agency",
      "google adwords": "/performance-marketing-company",
      "adwords optimization": "/performance-marketing-company",
      "ads optimization": "/performance-marketing-company",
      "wordpress development": "/wordpress-development-agency",
      "website development": "/web-development-agency",
      website: "/web-development-agency",
      "ecommerce website": "/web-development-agency",
      "paid marketing": "/creative-content-agency",
      "paid advertising": "/creative-content-agency",
      "display ads": "/display-advertising-agency",
      "search ads": "/search-engine-optimization-services",
      "youtube ads": "/branding-consulting-firm",
      "youtube marketing": "/brand-positioning-agency",
      "print design": "/print-design-agency",
      "website design": "/website-development-company",
      "web design": "/website-development-company",
      "web optimization": "/website-development-company",
      "customized website": "/website-development-company",
      "rank keywords": "/search-engine-optimization-services",
      "seo ramking": "/search-engine-optimization-services",
      contact: "/contact",
    };

    // Get the corresponding page based on the input value
    const inputValue = inputData.toLowerCase().trim();

    // Find a matching page based on input
    const matchingPage = Object.keys(pages).find((key) =>
      key.includes(inputValue)
    );

    if (matchingPage) {
      // Redirect to the matched page
      navigate(pages[matchingPage]);
    } else {
      // Navigate to the home page if no match found
      navigate(pages["home"]);
    }
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is Google Workspace?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Google Workspace is a suite of cloud-based productivity and collaboration tools developed by Google. It includes applications like Gmail, Google Drive, Google Docs, Google Sheets, Google Slides, Google Meet, and more. It's designed to help individuals and teams work together efficiently and effectively",
        },
      },
      {
        "@type": "Question",
        name: "Can I use my domain with Google Workspace?",
        acceptedAnswer: {
          "@type": "Answer",
          text: " Yes, you can use your domain with Google Workspace. This allows you to have professional custom email addresses using your domain name (e.g., yourname@yourcompany.com) and gives your organization a more professional appearance",
        },
      },
      {
        "@type": "Question",
        name: "Is data stored in Google Workspace secure?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Google takes security seriously and employs various measures to protect the data stored in Google Workspace. This includes encryption, advanced authentication options, regular security audits, and compliance with industry standards",
        },
      },
    ],
  };
  return (
    <>
      <Seo
        title="Google Workspace Provider in Bangalore | Hyderbad"
        description="Google workspace provider in bangalore and hyderabad. Discover how Google Workspace can transform your work, streamline communication, and power your business forward."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="best google workspace service provider in India ,
        google workspace service in hyderbad,
        google workspace in hyderbad"
        searchengines="ALL"
        canonical="https://www.grank.co.in/google-workspace-service-provider"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/email/email-banner1.png"
            alt="G-Rank"
            title="Email"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/email/email-banner1-mobile.png"
            alt="G-Rank"
            title="Email"
          />

          <div className="email-banner-div1">
            <motion.h1
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Google Workspace <br />
              Service Provider
            </motion.h1>
            <motion.h2
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Simplify Work with Google Workspace
            </motion.h2>

            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Find Out More</NavLink>
            </motion.h3>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ padding: "80px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/email-hosting-provider"
              >
                <p>Email</p>
              </NavLink>

              <Typography color="#fff">Google Workspace</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* second section start */}
        <div>
          <div className="whychoose-sec">
            <div className="container-fluid">
              <h4 className="email-sub-head">Benefits of Google Workspace </h4>
              <Slider {...settings4}>
                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">
                      Seamless <br />
                      Collaboration
                    </h2>
                    <p>
                      Google Workspace enables real-time teamwork worldwide.
                      Simultaneously create, edit, and refine documents,
                      spreadsheets, and presentations.
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">
                      Enhanced
                      <br />
                      Communication
                    </h2>
                    <p>
                      Utilize Google Meet for easy connections. HD video
                      meetings with up to 500 participants, global reach, screen
                      sharing, and real-time chat enhance effective
                      communication and time management.
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">
                      Advanced
                      <br />
                      Security
                    </h2>
                    <p>
                      Google Workspace provides top-notch data protection. It
                      benefits from two-factor authentication, encrypted
                      connections, and enterprise-level security features.
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">
                      Smarter Email
                      <br />
                      Experience
                    </h2>
                    <p>
                      Experience Gmail's intuitive email management. Organize
                      with a clean inbox, strong spam filters, and quick search.
                      Smart suggestions and automated responses keep you on
                      track.
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">
                      Customizable
                      <br /> Workspace
                    </h2>
                    <p>
                      Customize Google Workspace to suit your needs. Personalize
                      the interface, integrate extensions, and seamlessly link
                      third-party apps.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {/* second section end */}
        {/* logo section start */}
        <section>
          <div className="container-fluid  google-workspace-div">
            <div className="row">
              <div className="col-12">
                <h4 className="email-sub-head">
                  Google Workspace Services & Plans
                </h4>
              </div>
              <div className="col-12 ">
                <div className="row">
                  {/* <h4>most popular</h4> */}
                  {/* <h5>Business Starter</h5> */}
                  <AddToCartGoogles />
                </div>
              </div>
              <div className="col-12">
                <h5 className="google-content-h5">
                  Business Starter, Business Standard, and Business Plus plans
                  can be purchased for a maximum of 300 users. There is no
                  minimum or maximum user limit for Enterprise plans.
                  <br />
                  Google Workspace customers may have access to additional
                  features for a limited promotional period.
                  <br />
                  <br />
                  *Offer available to new Google Workspace customers only. This
                  introductory price is only available for the first 20 users
                  added, for 12 months. Standard pricing will apply to all users
                  after 12 months.
                  <br /> Actual price per user may vary by up to ~0.01% due to
                  rounding. Final price will be shown before completing sign-up.
                  <br />
                  <br />
                  **Google Workspace provides flexible pooled storage per user
                  that is shared across the organization. Visit our Help Center
                  to learn more about getting additional storage for your
                  organization.
                </h5>
              </div>
              {/* <div className="col-12 col-md-3 col-lg-3 email-sub-logo-div2">
                
                <h5>Business Standard</h5>
                <div className="email-sub-logo-div">
                  <h3>₹920*</h3>
                  <h4>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/rupee.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    736<span>INR</span>
                  </h4>
                  <h2>
                    per user/month, one-year
                    <br /> commitment
                  </h2>
                  <h6>ADD TO CART</h6>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    Custom & secure business email
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    150-participant video meetings + Recording
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    2 TB pooled storage per user
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    Security & management controls
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    Standard support (paid upgrade to enhanced support)
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 email-sub-logo-div2">
            
                <h5>Business Plus</h5>
                <div className="email-sub-logo-div">
                  <h3>₹920*</h3>
                  <h4>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/rupee.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    1380<span>INR</span>
                  </h4>
                  <h2>
                    per user/month, one-year
                    <br /> commitment
                  </h2>
                  <h6>ADD TO CART</h6>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    Custom & secure business email +ediscovery, retention
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    150 participant video meetings + Recording, attendance
                    tracking
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    5 TB pooled storage per user
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    Enhanced Security & management controls, including Vault &
                    advanced endpoint management
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    Standard support (paid upgrade to enhanced support)
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 email-sub-logo-div2">
               
                <h5>Enterprise</h5>
                <div className="email-sub-logo-div">
                  <h3>₹230*</h3>
                  <h4>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/rupee.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    136.90<span>INR</span>
                  </h4>
                  <h2>Contact Sales for pricing</h2>
                  <h6>ADD TO CART</h6>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    Custom & secure business email + ediscovery, retention,
                    S/MIME encryption
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    1000 participant video meetings + recording, attendance
                    tracking, noise cancellation, in-domain live streaming
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    5 TB pooled storage per user, with ability to request more
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    Advanced security management & compliance controls,
                    including Vault, DLP, data regions and enterprise endpoint
                    management
                  </p>
                  <p>
                    <img
                      className="img-fluid "
                      src="assest/photos/email/check.png"
                      alt="G-Rank"
                      title="Email"
                    />
                    Enhanced support (paid upgrade to enhanced support)
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/* logo section end */}

        {/* third section start */}
        <div>
          <div className="whychoose-sec">
            <div className="container-fluid">
              <h4 className="email-sub-head">Why Choose G-Rank? </h4>
              <Slider {...settings4}>
                <div className="slider-div4">
                  <div className="op">
                    <h2 className="slider-img4">Email and Communication</h2>
                    <p>
                      G Suite offers Gmail for Business, a reliable and
                      feature-rich email platform with customizable domains,
                      advanced spam filters, and large storage capacities,
                      making communication within the organization more
                      professional and organized.
                    </p>
                  </div>
                </div>

                <div className="slider-div4">
                  <div className="op">
                    <h2 className="slider-img4"> Cloud Storage</h2>
                    <p>
                      \ Google Drive provides ample cloud storage, allowing
                      teams to store, access, and share files and folders
                      securely from anywhere, promoting easy file management and
                      reducing the need for local storage solutions.
                    </p>
                  </div>
                </div>

                <div className="slider-div4">
                  <div className="op">
                    <h2 className="slider-img4">
                      Customization and Integration
                    </h2>
                    <p>
                      G Suite offers APIs and integrations with other popular
                      tools, allowing organizations to customize and extend the
                      functionality of Google Workspace to meet specific
                      business needs.
                    </p>
                  </div>
                </div>

                <div className="slider-div4">
                  <div className="op">
                    <h2 className="slider-img4">User-Friendly Interface</h2>
                    <p>
                      Google Workspace's user-friendly interface requires
                      minimal training, allowing employees to adopt and make the
                      most of the tools available quickly.
                    </p>
                  </div>
                </div>

                <div className="slider-div4">
                  <div className="op">
                    <h2 className="slider-img4"> Reliability</h2>
                    <p>
                      Google's infrastructure provides high uptime and
                      reliability, reducing the risk of downtime and ensuring
                      your team can access their tools and data whenever needed.
                    </p>
                  </div>
                </div>
                <div className="slider-div4">
                  <div className="op">
                    <h2 className="slider-img4">Collaboration Tools</h2>
                    <p>
                      Google Workspace provides powerful collaboration tools
                      like Google Docs, Sheets, Slides, and Forms that allow
                      multiple users to work on documents simultaneously, making
                      real-time collaboration seamless and efficient.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {/* third section end */}
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}

        {/* FAQs section start*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="security-circle-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  FAQ's
                </motion.h4>
              </div>
              <div className="col-md-12">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          What is Google Workspace?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Google Workspace is a suite of cloud-based
                          productivity and collaboration tools developed by
                          Google. It includes applications like Gmail, Google
                          Drive, Google Docs, Google Sheets, Google Slides,
                          Google Meet, and more. It's designed to help
                          individuals and teams work together efficiently and
                          effectively.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          Can I use my domain with Google Workspace?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse "
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Yes, you can use your domain with Google Workspace.
                          This allows you to have professional custom email
                          addresses using your domain name (e.g.,
                          yourname@yourcompany.com) and gives your organization
                          a more professional appearance.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => setOpen3(!open3)}
                        >
                          Is data stored in Google Workspace secure?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse "
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Google takes security seriously and employs various
                          measures to protect the data stored in Google
                          Workspace. This includes encryption, advanced
                          authentication options, regular security audits, and
                          compliance with industry standards.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}
        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links For You
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/*Input start */}
        <section>
          <div className="performance-footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/security/white-and-blue-back.png"
              alt="G-Rank"
              title="grank"
            />
            <div className="performance-footer-div-text">
              <h4>Learn more about our sevices</h4>
              <form className="searchArea" onSubmit={handleSubmit}>
                <button type="submit" tabindex="2">
                  Explore
                </button>
                <div className="inputDiv">
                  <input
                    type="text"
                    id="inputData"
                    name="inputData"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    requiredtabindex="1"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
        {/*Input start */}
      </section>
    </>
  );
};

export default GoogleWorkspace;
