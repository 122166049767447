export default {
  serverBaseurl: "https://server.grank.co.in",
  api: {
    user: {
      create: "/users",
      createlogin: "/users/login",
      update: "/users/",
      delete: "/users/",
      getone: "/users/",
      getAll: "/users",
    },
    auth: {
      create: "/auth/",
      update: "/auth/",
      delete: "/auth/",
      getone: "/auth/",
      getAll: "/auth",
    },

    addtocart: {
      create: "/addtocart",
      update: "/addtocart/",
      delete: "/addtocart/",
      getOne: "/addtocart/",
      getAll: "/addtocart",
    },
    addtocartsecu: {
      create: "/addtocartsecu",
      update: "/addtocartsecu/",
      delete: "/addtocartsecu/",
      getOne: "/addtocartsecu/",
      getAll: "/addtocartsecu",
    },
    googleworkspace: {
      create: "/googlewarkspace",
      update: "/googlewarkspace/",
      delete: "/googlewarkspace/",
      getOne: "/googlewarkspace/",
      getAll: "/googlewarkspace",
    },
    microsoft: {
      create: "/microsoft",
      update: "/microsoft/",
      delete: "/microsoft/",
      getOne: "/microsoft/",
      getAll: "/microsoft",
    },
    radiffmail: {
      create: "/rediffmail",
      update: "/rediffmail/",
      delete: "/rediffmail/",
      getOne: "/rediffmail/",
      getAll: "/rediffmail",
    },
  },
};
