import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { motion } from "framer-motion";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Player from "./Player";
import Player2 from "./Player2";
import Header from "../header2/Header";
import Seo from "../seo/Seo";
import { NavLink } from "react-router-dom";
import CustomerContext from "../blog-folder/CustomerContext";

const Homes = () => {
  const { handleOpen } = React.useContext(CustomerContext);

  // useState section start--------------------------------------
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaying2, setIsPlaying2] = useState(false);

  // acoordian button

  //  useState section end----------------------------------------
  // Slider section start-----------------------------------------
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="slider-custome-button-next" onClick={onClick}>
        <KeyboardArrowRightIcon className="forword-arrow" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="slider-custome-button-prev" onClick={onClick}>
        <KeyboardArrowLeftIcon className="backword-arrow" />
      </div>
    );
  }
  const settings3 = {
    dots: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    waitForAnimate: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings4 = {
    dots: false,
    speed: 2000,
    autoplay: true,
    infinite: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          rtl: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rtl: true,
        },
      },
    ],
  };
  const settings5 = {
    speed: 2000,
    autoplay: true,
    rtl: true,
    infinite: true,
    dots: false,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          rtl: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rtl: true,
        },
      },
    ],
  };

  // slider section end -------------------------------------------------------

  const settings10 = {
    dots: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
    beforeChange: () => {
      console.log("after change");
      setIsPlaying(false);
      setIsPlaying2(false);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function SampleNextArrow2(props) {
    const { onClick } = props;
    return (
      <div className="main-slider-custome-button-next" onClick={onClick}>
        <KeyboardArrowRightIcon className="main-forword-arrow" />
      </div>
    );
  }

  function SamplePrevArrow2(props) {
    const { onClick } = props;
    return (
      <div className="main-slider-custome-button-prev" onClick={onClick}>
        <KeyboardArrowLeftIcon className="main-backword-arrow" />
      </div>
    );
  }
  // video autoplay start
  //const [selectedVideoId, setSelectedVideoId] = useState([]);
  const vidRef = useRef();
  // const updateVideoHandler = async (videoId, videoTitle) => {
  //   setSelectedVideoId(videoId);
  //   if (!selectedVideoId) {
  //     vidRef?.current?.player?.player?.onPause();
  //   }
  // };
  useEffect(() => {
    vidRef.current.play();
  }, []);

  // video autoplay end
  // ------------------------------schema start
  const schema = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "@id": "https://www.grank.co.in/#webpage",
    url: "https://www.grank.co.in/",
    inLanguage: "en-US",
    name: "Digital Marketing Company in Bangalore and Hyderabad| SEO Agency in Bangalore and Hyderabad",
    datePublished: "2017-03-04T05:42:42+00:00",
    dateModified: "2020-05-08T07:39:44+00:00",
    website: {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      name: "Grank - SEO Digital Marketing Company in Hyderabad India",
      alternateName: "Grank",
      url: "https://www.grank.co.in/",
    },
    localBusiness: {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "Grank - SEO Digital Marketing Company in Hyderabad India",
      image: "https://www.grank.co.in/favicon.ico",
      telephone: "91775 07999",
      email: "contact@shoutnhike.com",
      address: {
        "@type": "PostalAddress",
        streetAddress:
          "Second Floor, R K Lake Tower, Service Road, Subramani Nagar, Guddadahalli, Hebbal, Bengaluru, Karnataka ",
        addressLocality: "Hyderabad",
        addressRegion: "karnataka",
        addressCountry: "India",
        postalCode: "560024",
      },
      url: "https://www.grank.co.in/",
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "4.9",
        bestRating: "5",
        ratingCount: "61",
      },
      priceRange: "Call ? 91775 07999 | ? charankumarkommineni@grank.co.in",
    },
    organization: {
      "@type": "Organization",
      url: "https://www.grank.co.in/",
      logo: "https://www.grank.co.in/favicon.ico",
    },
  };
  // ------------------------------schema end
  return (
    <>
      <Seo
        title="Digital Marketing Agency In India - G-Rank"
        description="Discover G-Rank, a leading digital marketing agency in India, offering innovative solutions to elevate your brand's online presence and drive growth."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="best digital marketing company in bangalore and hyderabad,
        digital marketing agency in bangalore and hyderbad"
        searchengines="ALL"
        canonical="https://www.grank.co.in"
        schema={schema}
      ></Seo>

      <section className="section-top">
        <Header />
        <Slider {...settings10}>
          <section>
            <div className="banner-new mobile-none">
              <img
                className="img-fluid w-100 "
                src="assest/photos/header-banner.png"
                alt="G-Rank"
              />
              <div className="banner-new-div">
                <h4>
                  <span className="word"></span>
                </h4>
                <h1>Digital Marketing Agency</h1>
                <motion.h3
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  onClick={handleOpen}
                >
                  <NavLink to="">Get Started</NavLink>
                </motion.h3>
              </div>
              <motion.h4
                className="slider-heading-top"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Our Clients
              </motion.h4>
            </div>

            <div className="banner-new desktop-none">
              <img
                className="img-fluid w-100 "
                src="assest/photos/banner-mobile-main.png"
                alt="G-Rank"
              />
              <div className="banner-new-div">
                <h4>
                  <span className="word"></span>
                </h4>
                <h2>
                  Innovative Ideas comes from
                  <br />
                  the right mind set
                </h2>

                <motion.h3
                  href="#"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  onClick={handleOpen}
                >
                  <NavLink to="">Get Started</NavLink>
                </motion.h3>
              </div>
              <motion.h4
                className="slider-heading-top"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Our Clients
              </motion.h4>
            </div>
          </section>
          {/* first slide */}

          <section>
            <div className="banner-new mobile-none">
              <Player setIsPlaying={setIsPlaying} isPlaying={isPlaying} />

              <motion.h4
                className="slider-heading-top"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Our Clients
              </motion.h4>
            </div>

            <div className="banner-new desktop-none">
              <Player2 setIsPlaying2={setIsPlaying2} isPlaying2={isPlaying2} />
              <motion.h4
                className="slider-heading-top"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Our Clients
              </motion.h4>
            </div>
          </section>
          <section>
            <div className="banner-new mobile-none">
              <video
                className="img-fluid w-100 "
                src="assest/videos/main-banner-video-new2.mp4"
                ref={vidRef}
                muted
                autoPlay
                loop
                // webkit-playsInline={true}
                playsInline={true}
              />
              <div className="banner-new-div2">
                <h4>
                  <span className="word"></span>
                </h4>
                <h2>
                  Innovative Ideas comes from
                  <br />
                  the right mind set
                </h2>

                <motion.h3
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  <NavLink to="" onClick={handleOpen}>
                    Get Started
                  </NavLink>
                </motion.h3>
              </div>
              <motion.h4
                className="slider-heading-top"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Our Clients
              </motion.h4>
            </div>

            <div className="banner-new desktop-none">
              <video
                className="img-fluid w-100 "
                src="assest/videos/main-banner-video-mobile-new.mp4"
                ref={vidRef}
                muted
                autoPlay
                loop
                // webkit-playsInline={true}
                playsInline={true}
              />
              <div className="banner-new-div2">
                <h4>
                  <span className="word"></span>
                </h4>
                <h2>
                  Innovative Ideas comes from
                  <br />
                  the right mind set
                </h2>

                <motion.h3
                  href="#"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  onClick={handleOpen}
                >
                  <NavLink to="">Get Started</NavLink>
                </motion.h3>
              </div>
              <motion.h4
                className="slider-heading-top"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Our Clients
              </motion.h4>
            </div>
          </section>
          {/* second slider */}
        </Slider>
        {/* top banner end */}
        {/* our client start */}
        <section>
          <div className="container-fluid">
            <div>
              <Slider {...settings3}>
                <NavLink to="eco-life">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/eco-life.png"
                      alt="G-Rank"
                      title="eco-life"
                    />
                  </div>
                </NavLink>
                <NavLink to="cms">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/cms.png"
                      alt="G-Rank"
                      title="cms"
                    />
                  </div>
                </NavLink>
                <NavLink to="jayabheri">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/jaya.png"
                      alt="G-Rank"
                      title="jaya"
                    />
                  </div>
                </NavLink>
                <NavLink to="performax">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/performax.png"
                      alt="G-Rank"
                      title="performax"
                    />
                  </div>
                </NavLink>
                <NavLink to="dirak">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/dirak.png"
                      alt="G-Rank"
                      title="dirak"
                    />
                  </div>
                </NavLink>
                <NavLink to="signature">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/signature.png"
                      alt="G-Rank"
                      title="signature"
                    />
                  </div>
                </NavLink>
                <NavLink to="shodhana">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/shodhana.png"
                      alt="G-Rank"
                      title="shodhana"
                    />
                  </div>
                </NavLink>
                <NavLink to="ramky">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/ramky.png"
                      alt="G-Rank"
                      title="ramky"
                    />
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* our client end */}
        {/* selector section start */}
        <section className="section-margin-selecter">
          <div className="container">
            <div className="row">
              <div className="col-12 selecter-section">
                <motion.h3
                  initial={{ opacity: 0, scale: 0, x: -100 }}
                  whileInView={{ opacity: 1, scale: 1, x: 0 }}
                  transition={{ duration: 1 }}
                >
                  I'm looking for :
                </motion.h3>
                <ul>
                  <li className="hov-drop ">
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive ? "#436fe8" : "#436fe8",
                      })}
                      to=""
                    >
                      <span className="about-us">Branding</span>
                      <ArrowDropDownIcon className="drop-arrow" />
                    </NavLink>
                    <ul className="main-drop">
                      <NavLink to="/branding-agency">
                        <li>
                          <span className="drop-new-span">Branding</span>
                        </li>
                      </NavLink>
                      <NavLink to="/performance-marketing-company">
                        <li>
                          <span className="drop-new-span">
                            Performance Marketing
                          </span>
                        </li>
                      </NavLink>
                      <NavLink to="/organic-marketing-agency">
                        <li>
                          <span className="drop-new-span">
                            Organic Marketing
                          </span>
                        </li>
                      </NavLink>
                      <NavLink to="/creative-content-agency">
                        <li>
                          <span className="drop-new-span">
                            Creative & Content
                          </span>
                        </li>
                      </NavLink>
                      <NavLink to="/social-media-agency">
                        <li>
                          <span className="drop-new-span">Social Media</span>
                        </li>
                      </NavLink>
                      <NavLink to="/web-development-agency">
                        <li>
                          <span className="drop-new-span">Web Development</span>
                        </li>
                      </NavLink>
                      <NavLink to="/domain">
                        <li>
                          <span className="drop-new-span">Domain</span>
                        </li>
                      </NavLink>
                      <NavLink to="/hosting-service">
                        <li>
                          <span className="drop-new-span">Web Hosting</span>
                        </li>
                      </NavLink>
                      <NavLink to="/web-security-services">
                        <li>
                          <span className="drop-new-span">Security</span>
                        </li>
                      </NavLink>
                      <NavLink to="/email-hosting-provider">
                        <li>
                          <span className="drop-new-span">Email</span>
                        </li>
                      </NavLink>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* selector section end */}
        {/* our team section start*/}
        <section className="section-margin-selecter2">
          <div className="">
            <ul className="nav nav-tabs2" id="myTab" role="tablist">
              <li className=" nav-item2" role="presentation">
                <button
                  className="nav-link2 active"
                  id="home-tab2"
                  data-toggle="tab"
                  data-target="#home2"
                  type="button"
                  role="tab"
                  aria-controls="home2"
                  aria-selected="true"
                >
                  Our Core Team
                </button>
              </li>
              <li className="nav-item2" role="presentation">
                <button
                  className="nav-link2 "
                  id="home-tab1"
                  data-toggle="tab"
                  data-target="#home1"
                  type="button"
                  role="tab"
                  aria-controls="home1"
                  aria-selected="true"
                >
                  Our story
                </button>
              </li>
              <li className="nav-item2" role="presentation">
                <button
                  className="nav-link2"
                  id="profile-tab2"
                  data-toggle="tab"
                  data-target="#profile2"
                  type="button"
                  role="tab"
                  aria-controls="profile2"
                  aria-selected="false"
                >
                  What We Do
                </button>
              </li>
              <li className="nav-item2" role="presentation">
                <button
                  className="nav-link2"
                  id="contact-tab3"
                  data-toggle="tab"
                  data-target="#contact3"
                  type="button"
                  role="tab"
                  aria-controls="contact3"
                  aria-selected="false"
                >
                  Our Methodology
                </button>
              </li>
              <li className="nav-item2" role="presentation">
                <button
                  className="nav-link2"
                  id="contact-tab4"
                  data-toggle="tab"
                  data-target="#contact4"
                  type="button"
                  role="tab"
                  aria-controls="contact4"
                  aria-selected="false"
                >
                  Why Choose Us
                </button>
              </li>
              <li className="nav-item2" role="presentation">
                <NavLink to="/partnership-collaboration">
                  <button
                    className="nav-link2"
                    id="contact-tab4"
                    data-toggle="tab"
                    data-target="#contact4"
                    type="button"
                    role="tab"
                    aria-controls="contact4"
                    aria-selected="false"
                  >
                    Partnership
                  </button>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active meet-core-div"
              id="home2"
              role="tabpanel"
              aria-labelledby="home-tab2"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <motion.div
                      className="box4"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <div className="hid-box3">
                        <img
                          className=""
                          src="assest/photos/gallery/gallery3-small.jpg"
                          alt="G-Rank"
                          title="CEO"
                        />
                      </div>
                      <div className="hid-box4">
                        Singam Raghavendra
                        <h5> Founder & CEO, Bangalore</h5>
                      </div>
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <motion.div
                      className="box4"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <div className="hid-box3">
                        <img
                          className=""
                          src="assest/photos/gallery/gallery3-small2.jpg"
                          alt="G-Rank"
                          title="CEO"
                        />
                      </div>
                      <div className="hid-box4">
                        Suresh Babu
                        <h5> Founder & CEO, Hyderabad</h5>
                      </div>
                    </motion.div>
                  </div>
                  <div className="col-12 ">
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive ? "#000" : "#000",
                      })}
                      to="/our-team"
                    >
                      <span className="meet-our-festive-moment3">
                        Meet Our Team
                        <img
                          className="img-fluid"
                          src="assest/photos/our-team/right-arrow.png"
                          alt="G-Rank"
                        />
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane "
              id="home1"
              role="tabpanel"
              aria-labelledby="home-tab1"
            >
              <img
                className="img-fluid w-100 mobile-none"
                src="assest/photos/our-team-timeline1.png"
                alt="grank"
                title="timeline"
              />
              <img
                className="img-fluid w-100 desktop-none"
                src="assest/photos/our-team-timeline2.png"
                alt="grank"
                title="timeline"
              />
            </div>
            <div
              className="tab-pane fade nav-section-content2"
              id="profile2"
              role="tabpanel"
              aria-labelledby="profile-tab2"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 ourteam-what-we-do-text">
                    <div className="typewriter">
                      <h3>
                        WHAT
                        <span>WE</span>
                        <span className="do">DO</span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 nav-tab-text">
                    {/* <h2 className="slider-heading1">WHO ARE WE</h2> */}
                    <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-text4">
                          Powering business since 2010!
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-text3">
                          Develop online marketing strategies
                          <span className="d-block">
                            tailored to client goals.
                          </span>
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-text3">
                          Optimize websites for better search
                          <span className="d-block">
                            engine rankings (SEO).
                          </span>
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-text3">
                          Run paid advertising campaigns on
                          <span className="d-block">search engines (SEM).</span>
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-text3">
                          Drive traffic, generate leads, and increase
                          <span className="d-block">online sales.</span>
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-text3">
                          Manage and curate social media profiles
                          <span className="d-block"> for brand presence.</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade nav-section-content"
              id="contact3"
              role="tabpanel"
              aria-labelledby="contact-tab3"
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 align-self-center methodo-img-div">
                    <motion.img
                      className="img-fluid  methodo-img1"
                      src="assest/photos/methodology/methodo-img1.png"
                      alt="grank"
                      title="method"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.6 }}
                    />
                    <motion.img
                      className="img-fluid  methodo-img2"
                      src="assest/photos/methodology/methodo-img2.png"
                      alt="grank"
                      title="method"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.6 }}
                    />
                    <motion.img
                      className="img-fluid  methodo-img3"
                      src="assest/photos/methodology/methodo-img3.png"
                      alt="grank"
                      title="method"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.6 }}
                    />
                    <motion.img
                      className="img-fluid  methodo-img4"
                      src="assest/photos/methodology/methodo-img4.png"
                      alt="grank"
                      title="method"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.6 }}
                    />
                    <motion.img
                      className="img-fluid  methodo-img5"
                      src="assest/photos/methodology/methodo-img5.png"
                      alt="grank"
                      title="method"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.6 }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <motion.div
                      className="methodo-texting"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      <h3>Assessment</h3>
                      <p>
                        Where are you at right now? What are your growth goals
                        and objectives? Where are the gaps in your current
                        marketing efforts that you need to address? What are the
                        opportunities to get there?
                      </p>

                      <h3>Strategy</h3>
                      <p>
                        Our strategy comes from talking with your leads and
                        buyers to understand why they are buying your product or
                        service, what problem you solve for them, and what they
                        find interesting about your site. Then we look at your
                        business goals and objectives and see where the gaps are
                        between perception of the website and the reality of how
                        the website is delivering.
                      </p>

                      <h3>Foundation</h3>
                      <p>
                        Build your audience. Now that you have your map of how
                        to get to the top of the mountain, your strategy will
                        help you understand what tools you will need to put in
                        place to achieve your objectives.
                      </p>
                      <h3>Campaigns</h3>
                      <p>
                        Increase the value of each contact with strategic
                        campaigns
                      </p>
                      <h3>Optimization</h3>
                      <p>More leads for more transactions.</p>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade nav-section-content2"
              id="contact4"
              role="tabpanel"
              aria-labelledby="contact-tab4"
            >
              <img
                className="img-fluid w-100 why-choose-us-img mobile-none"
                src="assest/photos/why-choose-us/ourteam-why-choose-us-small2.png"
                alt="grank"
                title="why-choose-us"
              />
              <img
                className="img-fluid w-100 why-choose-us-img desktop-none"
                src="assest/photos/why-choose-us/why-choose-img-mobile.png"
                alt="grank"
                title="why-choose-us"
              />
              <div className="why-choose-us-texting">
                <h2>
                  With years of valuable experience and being the best digital
                  marketing agency in Bangalore and Hyderabad, our talented
                  group brings a wealth of expertise that guarantees results. We
                  stay up-to-date with the latest trends, strategies, and
                  technologies to ensure you are always ahead of your
                  competitors.
                </h2>
              </div>
            </div>
          </div>
        </section>
        {/* our team section start */}
        {/* action white start*/}
        <div className="action-white-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>

          <NavLink
            to=""
            style={({ isActive }) => ({
              color: isActive ? "#000" : "#fff",
            })}
          >
            <motion.p
              className="action-white-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action white end*/}
        {/* Testinomial section start */}
        <section className="testimonial-section-margin">
          <div className="container">
            <h3 className="testinomial-heading">Google Reviews</h3>
            <Slider {...settings2}>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      Provided best services and we have got minimum 10-15
                      Genuine Enquiries from G-Rank for every month also results
                      in booking of orders & this is the best platform for
                      marketing purposes like Newspapers,Social media
                      Advertisements,etc. My suggestion is to take the service
                      from G-Rank for better company profile development and
                      orders booking.
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img "
                        src="assest/photos/review-logo/techmechcranes.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name">
                        <h3>Tech Mech Cranes</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      Six-years journey with G-Rank has elevated our business
                      and consistent support from the team,driven quality leads
                      to our industry. We thank you for providing us with value
                      services. Best wishes from Ameenji Rubber Pvt Ltd ...
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6 " id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img "
                        src="assest/photos/review-logo/ameenjirubber.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name2">
                        <h3>Ameenji Rubber Private Limited</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      Associated with G-Rank for the last two years for my two
                      institutions. This team is a team of action and delivers
                      what it promises. Very happy to be associated with them.
                      Trustworthy company.
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img"
                        src="assest/photos/review-logo/snis.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name4">
                        <h3>Shantiniketan International School</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      G-Rank has experienced professionals who can help you in
                      the digital way of promoting your website in all the
                      possible ways. I would recommend G-Rank if you are looking
                      for your business/product promotions
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img"
                        src="assest/photos/review-logo/aabt.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name5">
                        <h3> Advance Aqua Biotech Private Limited</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      A comprehensive digital strategy by G-Rank and a
                      continuous service provided by their team as per our
                      requirement has not only improved our website quality but,
                      also provided the quality leads to our school.
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img"
                        src="assest/photos/review-logo/snvp.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name6">
                        <h3> SNVP School</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      An excellent team, good approach, aggressive in
                      promotion,challenging in nature. Satisfied with their
                      performance. Total team is productive.Our appreciation to
                      G rank.
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img"
                        src="assest/photos/review-logo/ace.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name7">
                        <h3> Ace Constructions</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      we have been associated with digital marketing services
                      for 3 months and the results are appreciable. We are happy
                      with the services provided and looking to extend further.
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/review-logo/esennar.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name8">
                        <h3>Esennar Transformers</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      Good Service from the G-Rank. We recently signed for 1 yr
                      contract with this team. The service and web-design is
                      good !!
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/review-logo/landmark.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name9">
                        <h3>Candeur Landmark</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      We have been associated with G-Rank for the last six
                      years. All our social media, digital marketing, website
                      maintenance, and lead generation activities are well taken
                      care of by the highly professional team at G-Rank. We are
                      happy with the quick response and resolution extended by
                      Mr. Sandeep. Expecting the same in our future projects
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/review-logo/ecolife.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name10">
                        <h3>Ecolife Developers</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      Best solution for Digital marketing services and also good
                      support and service from the team especially anjali- will
                      respond quickly and address the issue properly
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/review-logo/bharadwaj.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name11">
                        <h3> Bharadwaj Packaging</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      G-Rank is very professional and prompt in resolution of
                      all issues. They have been handling our account for many
                      years now and we are very happy with their service.
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/review-logo/rr.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name12">
                        <h3> RR Stone Products</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      They have been very cooperative and support all through
                      our discussions . The service is very good and prompt.
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/review-logo/tsg.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name13">
                        <h3> TSG Audio Innovations</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      We have been working with G-Rank for the last 18months and
                      getting relevant enquiries. Especially their remarketing
                      banner on the webpage is very effective. Very nice staff.
                      Good to be a partner with G-rank for digital marketing.
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/review-logo/dirak.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name14">
                        <h3>Dirak</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      For the last 2+ years we have been associated with G-Rank
                      for Website, Gsuite, and promotions.Recently we launched
                      our completely revamped website and couldn't be any
                      happier with the way the site turned out. Our old website
                      with outdated graphics, fonts, etc., was transformed into
                      a modern and incredibly attractive site.We are also quite
                      happy with their service visits, coordination, and
                      follow-ups and they are quick enough in responding to our
                      concerns.
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/review-logo/ravooslabs.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name15">
                        <h3>Ravoos Laboratories</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      we have been associated with the Grank for the last two
                      and half year , their support staff is extremely helpful
                      would recommend this company to anybody
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/review-logo/raja.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name16">
                        <h3>Rajapushpa Properties</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="comma"
                    />
                    <motion.div
                      className="testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      We have been associated with G Rank for the last 1 year
                      and we are happy with the services and the enquiries
                      generated. We look forward to the same support in the
                      coming year.
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid"
                        src="assest/photos/rating5.png"
                        alt="G-Rank"
                      />
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/review-logo/primex.png"
                        alt="G-Rank"
                      />
                      <div className="testinomial-section-name17">
                        <h3> Primex Plastics</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>

        {/* client-testinomial-start */}
        <section className="section-margin  client-testimonial-texture">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-12 align-self-center">
                <h2 className="slider-heading2">Client Testimonials</h2>
                <p className="slider-heading-text">
                  Building lasting relationships through <br />
                  trust !!
                </p>
              </div>
              <div className="col-lg-7 col-md-7 col-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <Slider {...settings4}>
                      <div>
                        <div className="client-test-images">
                          <a
                            href="https://www.youtube.com/watch?v=NLqSse7ak2o"
                            className="popup-youtube"
                          >
                            <img
                              className="img-fluid background-blue-imgs"
                              src="assest/photos/client-images/aparanje-elite.png"
                              alt="G-Rank"
                              title="aparanje-elite"
                            />
                            <p>aparanje elite</p>
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="client-test-images">
                          <a
                            href="https://www.youtube.com/watch?v=0TM30ZDrj-s"
                            className="popup-youtube"
                          >
                            <img
                              className="img-fluid background-blue-imgs"
                              src="assest/photos/client-images/flowwell-pumps.png"
                              alt="G-Rank"
                              title="flowell-pumps"
                            />
                            <p>flowwell pumps</p>
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="client-test-images">
                          <a
                            className="popup-youtube"
                            href="https://www.youtube.com/watch?v=xeofusRUNFY"
                          >
                            <img
                              className="img-fluid background-blue-imgs"
                              src="assest/photos/client-images/gayathrisreenath.png"
                              alt="G-Rank"
                              title="gayathrisreenath"
                            />
                            <p>gayathrisreenath</p>
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="client-test-images">
                          <a
                            className="popup-youtube"
                            href="https://www.youtube.com/watch?v=kWCz-AXpm90"
                          >
                            <img
                              className="img-fluid background-blue-imgs"
                              src="assest/photos/client-images/nithinravoos.png"
                              alt="G-Rank"
                              title="nithinravoos"
                            />
                            <p>nithinravoos</p>
                          </a>
                        </div>
                      </div>
                    </Slider>
                  </div>
                  <div
                    className=" col-lg-6 col-md-6 col-12"
                    style={{ marginTop: 25 }}
                  >
                    <Slider {...settings5}>
                      <div>
                        <div className="client-test-images">
                          <a
                            href="https://www.youtube.com/watch?v=-2Ne0PmjAAE"
                            className="popup-youtube"
                          >
                            <img
                              className="img-fluid background-blue-imgs"
                              src="assest/photos/client-images/superolifins.png"
                              alt="G-Rank"
                              title="superolifins"
                            />
                            <p>superolifins</p>
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="client-test-images">
                          <a
                            href="https://www.youtube.com/watch?v=3dYygP489dY"
                            className="popup-youtube"
                          >
                            <img
                              className="img-fluid background-blue-imgs"
                              src="assest/photos/client-images/sain-medicals.png"
                              alt="G-Rank"
                              title="sain-medical"
                            />
                            <p>sain medicals</p>
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="client-test-images">
                          <a
                            href="https://www.youtube.com/watch?v=A1KK-tZ20oE"
                            className="popup-youtube"
                          >
                            <img
                              className="img-fluid background-blue-imgs"
                              src="assest/photos/client-images/rajeshjain.png"
                              alt="G-Rank"
                              title="rajeshjain"
                            />
                            <p>Rajesh jain</p>
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="client-test-images">
                          <a
                            href="https://www.youtube.com/watch?v=Gd-sbwbu5cs"
                            className="popup-youtube"
                          >
                            <img
                              className="img-fluid background-blue-imgs"
                              src="assest/photos/client-images/taapsirosebay.png"
                              alt="G-Rank"
                              title="taapsirrosebay"
                            />
                            <p>taapsirosebay</p>
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="client-test-images">
                          <a
                            href="https://www.youtube.com/watch?v=r79K9Dyhzv4"
                            className="popup-youtube"
                          >
                            <img
                              className="img-fluid background-blue-imgs"
                              src="assest/photos/client-images/vatsal-ecolife.png"
                              alt="G-Rank"
                              title="vatsal-ecolife"
                            />
                            <p>vatsal ecolife</p>
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="client-test-images">
                          <a
                            href="https://www.youtube.com/watch?v=2ZmTREedxwE"
                            className="popup-youtube"
                          >
                            <img
                              className="img-fluid background-blue-imgs"
                              src="assest/photos/client-images/vinaya.png"
                              alt="G-Rank"
                              title="vinaya"
                            />
                            <p>vinaya</p>
                          </a>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Client-testinomial-end */}

        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="slider-heading"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links For You
              </motion.h2>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h3 className="slider-img2">Branding</h3>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h3 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h3>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h3 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h3>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h3 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h3>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h3 className="slider-img2">Social Media</h3>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h3 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h3>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h3 className="slider-img2">Domain</h3>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h3 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h3>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h3 className="slider-img2">Security</h3>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h3 className="slider-img2">Email</h3>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/* Black-section-div start*/}
        <section>
          <div className="footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/banner-footer32.png"
              alt="G-Rank"
            />
            <div className="footer-div-text">
              <motion.p
                initial={{ opacity: 0, scale: 0, y: 100 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                Are you ready to boost
                <br /> your business ?
              </motion.p>

              <NavLink to="">
                <motion.p
                  className="footer-div-buttom"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  onClick={handleOpen}
                >
                  Contact Us <ArrowRightAltIcon />
                </motion.p>
              </NavLink>
            </div>
          </div>
        </section>
        {/* Black-section-div end*/}
      </section>
    </>
  );
};

export default Homes;
