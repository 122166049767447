import React, { useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import { NavLink, useNavigate } from "react-router-dom";
import Seo from "../seo/Seo";
//import AddToCart from "../../../features/frontend/addtocart/AddToCart";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";

const DedicatedHosting = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();

  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // -------------------
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",
      "domain book": "/domain-booking",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "branding consulting firm": "/branding-consulting-firm",
      "branding strategy consultant": "/brand-strategy-consultant",
      "brand positioning agency": "/brand-positioning-agency",
      "brand identity agency": "/brand-identity-agency",
      "branding communication agency": "/branding-communication-agency",
      "performance marketing company": "/performance-marketing-company",
      "social media marketing agency": "/social-media-marketing-agency",
      "search engine marketing agency": "/search-engine-marketing-agency",
      "organic marketing agency": "/organic-marketing-agency",
      "search engine optimization services":
        "/search-engine-optimization-services",
      "search engine optimization": "/search-engine-optimization-services",
      sem: "/search-engine-optimization-services",
      "social media optimization agency": "/social-media-optimization-agency",
      "creative content agency": "/creative-content-agency",
      "display advertising agency": "/display-advertising-agency",
      "website development company": "/website-development-company",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
      "seo services": "/search-engine-optimization-services",
      seo: "/search-engine-optimization-services",
      "web development": "/web-development-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "smm service": "/paid-marketing-agency",
      smm: "/paid-marketing-agency",
      "social media marketing": "/paid-marketing-agency",
      "social media optimization": "/organic-social-media-agency",
      "smo service": "/organic-social-media-agency",
      smo: "/organic-social-media-agency",
      "content marketing": "/content-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "brand marketing": "/branding-agency",
      "facebook optimization": "/social-media-agency",
      "instagram optimization": "/social-media-agency",
      "google adwords": "/performance-marketing-company",
      "adwords optimization": "/performance-marketing-company",
      "ads optimization": "/performance-marketing-company",
      "wordpress development": "/wordpress-development-agency",
      "website development": "/web-development-agency",
      website: "/web-development-agency",
      "ecommerce website": "/web-development-agency",
      "paid marketing": "/creative-content-agency",
      "paid advertising": "/creative-content-agency",
      "display ads": "/display-advertising-agency",
      "search ads": "/search-engine-optimization-services",
      "youtube ads": "/branding-consulting-firm",
      "youtube marketing": "/brand-positioning-agency",
      "print design": "/print-design-agency",
      "website design": "/website-development-company",
      "web design": "/website-development-company",
      "web optimization": "/website-development-company",
      "customized website": "/website-development-company",
      "rank keywords": "/search-engine-optimization-services",
      "seo ramking": "/search-engine-optimization-services",
      contact: "/contact",
    };

    // Get the corresponding page based on the input value
    const inputValue = inputData.toLowerCase().trim();

    // Find a matching page based on input
    const matchingPage = Object.keys(pages).find((key) =>
      key.includes(inputValue)
    );

    if (matchingPage) {
      // Redirect to the matched page
      navigate(pages[matchingPage]);
    } else {
      // Navigate to the home page if no match found
      navigate(pages["home"]);
    }
  };

  return (
    <>
      <Seo
        title="Dedicated Hosting Services in Bangalore | Hyderabad"
        description="Dedicated Hosting services in bangalore and hyderabad. Explore our services to experience the pinnacle of hosting solutions, with dedicated resources, enhanced performance, and top-tier security for your website."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="best dedicated hosting,
        best dedicated server hosting,
        buy dedicated server,
        top 10 dedicated server hosting service in india,
        dedicated hosting service"
        searchengines="ALL"
        canonical="https://www.grank.co.in/dedicated-hosting-services"
      ></Seo>
      <section className="section-top">
        <Header />
        <div>
          <div className="hosting-banner">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-5" id="order2">
                <div className="exp-hosting">
                  <motion.h1
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    Dedicated Hosting Provider
                  </motion.h1>

                  <motion.p
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    If you are looking for an reliable shared hosting provider
                    for optimal web performance, look no further!
                  </motion.p>

                  <motion.h3
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6 }}
                    onClick={handleOpen}
                  >
                    <NavLink to="">Explore</NavLink>
                  </motion.h3>
                </div>
                <Breadcrumbs
                  className="hosting-breadcumbs breadcrumbs"
                  aria-label="breadcrumb"
                >
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/"
                  >
                    <p>Home</p>
                  </NavLink>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/hosting-service"
                  >
                    <p>Hosting</p>
                  </NavLink>

                  <Typography color="#000">Dedicated Hosting</Typography>
                </Breadcrumbs>
              </div>
              <div className="col-12 col-md-7 col-lg-7" id="order1">
                <img
                  className="img-fluid w-100"
                  src="assest/photos/hosting/hosting-banner3.png"
                  alt="grank"
                  title="grank"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="explore-plans">
            <motion.h2
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            >
              Explore Your Dedicated Hosting Plan​
            </motion.h2>

            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Click Here</NavLink>
            </motion.h3>
          </div>
        </div>

        <div>
          <div className="features-sec2">
            <div className="container">
              <div className="titleh2 mt60">
                <h2>Benefits</h2>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="whychoose-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 slider-div5">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/key.png"
                      alt="grank"
                      title="grank"
                    />
                    <h2 className="slider-img5">Full Control</h2>
                    <p>
                      Exclusive access to resources and
                      <br /> configurations.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 slider-div5">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/equalizer.png"
                      alt="grank"
                      title="grank"
                    />
                    <h2 className="slider-img5">Customization</h2>
                    <p>
                      Tailor server settings to your
                      <br /> needs.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 slider-div5">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/high-speed.png"
                      alt="grank"
                      title="grank"
                    />
                    <h2 className="slider-img5">High Performance</h2>
                    <p>
                      Faster loading and handling of
                      <br /> traffic.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container shared-title-sec">
          <div className="titleh2 ">
            <h2>About Dedicated Hosting</h2>
            <p className="shared-title-p">
              It is an internet hosting solution where a specific business
              customer exclusively uses one or more physical servers. This
              arrangement grants the customer full control over the server(s),
              allowing them to customize and optimize it according to their
              specific needs in terms of performance and security.
            </p>
          </div>
        </div>

        {/* <div>
          <div className="bestprice-sec">
            <div className="container-fluid">
              <div className="titleh2w2">
                <h2>Why Choose Us For Dedicated Hosting</h2>
              </div>

              <section>
                <div className="">
                  <div className="overflow-div">
                    <div className=" p-0 table-static-div">
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p style={{ color: " #436fe8" }}>
                            Our Full Features Comparison
                          </p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Domain</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Hosting</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Website Maintenance</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>SSL Certificate</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Google Workspace Mails</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Google Marketing</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Social Media Marketing</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Branding</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Technical Support</p>
                        </div>
                      </h4>
                    </div>
                    <AddToCart />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div> */}
        {/* why choose us for shared hosting start */}
        <div>
          <div className="features-sec2">
            <div className="container">
              <div className="titleh2 mt60">
                <h2>Why Choose Us For Dedicated Hosting</h2>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="whychoose-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-3 col-lg-3 slider-div6">
                  <div className="op2">
                    <h2 className="slider-img5">
                      G-Rank <br />
                      Dedicated Server
                      <br /> Hosting
                    </h2>
                    <p>
                      Among the top 10 dedicated server hosting providers in
                      India.
                    </p>
                    <p>Reputation built on years of excellence and trust.</p>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3 slider-div6">
                  <div className="op2">
                    <h2 className="slider-img5">Features and Offerings</h2>
                    <p>
                      Cutting-Edge Infrastructure:
                      <br /> Delivering the best dedicated server hosting
                      experience.
                    </p>
                    <p>
                      Tailored Solutions:
                      <br />
                      Dedicated server hosting solutions customized to your
                      needs.
                    </p>
                    <p>
                      Robust Performance:
                      <br />
                      High-performance servers for demanding applications.
                    </p>
                    <p>
                      Enhanced Security:
                      <br />
                      Ensuring data safety and protection.
                    </p>
                    <p>
                      Unlimited Bandwidth:
                      <br />
                      Providing ample data transfer capacity.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3 slider-div6">
                  <div className="op2">
                    <h2 className="slider-img5">Customer Satisfaction</h2>
                    <p>
                      Unmatched Commitment:
                      <br />
                      Dedicated to ensuring customer satisfaction.
                    </p>
                    <p>
                      Expert Technical Support:
                      <br />
                      Promptly addressing issues and concerns.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3 slider-div6">
                  <div className="op2">
                    <h2 className="slider-img5">Affordability and Quality</h2>
                    <p>
                      Competitive Prices:
                      <br />
                      Affordable rates for dedicated server hosting.
                    </p>
                    <p>
                      No Compromises:
                      <br />
                      Maintaining high-quality service despite lower costs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* why choose us for shared hosting end */}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="branding-quick-link"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links
                <p className="d-block"> For You</p>
              </motion.h2>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}

        <section>
          <div className="performance-footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/branding/branding-footer-img.png"
              alt="G-Rank"
              title="grank"
            />
            <div className="performance-footer-div-text">
              <h4>Learn more about our sevices</h4>
              <form className="searchArea" onSubmit={handleSubmit}>
                <button type="submit" tabindex="2">
                  Explore
                </button>
                <div className="inputDiv">
                  <input
                    type="text"
                    id="inputData"
                    name="inputData"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    requiredtabindex="1"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default DedicatedHosting;
